module.exports = {
    "paging": {
        "pageSize": 30,
        "currentPageNum": 0,
        "total": 152
    },
    "listings": [
      {
            "additionalString": null, //yes
            "learnMore": false, //yes
            "feature": false, //yes
            "addressStr": "<span>257 Donahue Street</span> <span>Sausalito</span> <span>CA 00000</span>", //yes
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/257_donahue_sausalito.jpg", //yes
            "status": "Active", //yes
            "priceToShowStr": "$959,000", // yes
            "bedrooms": "2", //yes
            "bathFull": "3", //yes
            "virtualTourLink": "", //yes
      },
      {
            "additionalString": "Coming Soon", //yes
            "learnMore": false, //yes
            "feature": false, //yes
            "addressStr": "<span>729 Atrice Lane</span> <span>Pleasant Hill</span> <span>CA 00000</span>", //yes
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/729_Atrice_Ln_Pleasant Hill .jpg", //yes
            "status": "Active", //yes
            "priceToShowStr": "$949,000", // yes
            "bedrooms": "4", //yes
            "bathFull": "2", //yes
            "virtualTourLink": "", //yes
      },
      {
            "additionalString": null, //yes
            "learnMore": false, //yes
            "feature": true, //yes
            "addressStr": "<span>934 Hamilton Drive</span> <span>Pleasant Hill</span> <span>CA 00000</span>", //yes
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/934_Hamilton_drive_Pleasant_Hill.jpg", //yes
            "status": "Active", //yes
            "priceToShowStr": "$1,089,000", // yes
            "bedrooms": "4", //yes
            "bathFull": "3", //yes
            "virtualTourLink": "", //yes
      },
      {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>331 Swan Way</span> <span>Vallejo</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 374000,
            "priceToShowStr": "$374,000",
            "squareFeet": 1374,
            "lotSize": null,
            "lotFeet": 6098,
            "lotAcres": null,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      },
      {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>198 William Way</span> <span>Pittsburg</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 460000,
            "priceToShowStr": "$460,000",
            "squareFeet": 1492,
            "lotSize": null,
            "lotFeet": 5200,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      },
      {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>465 Sandstone Dr</span> <span>Vallejo</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 275000,
            "priceToShowStr": "$275,000",
            "squareFeet": 1056,
            "lotSize": null,
            "lotFeet": 3029,
            "lotAcres": null,
            "bedrooms": "2",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      },
      {
            "additionalString": "Duplex",
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>3853 Broadway Street</span> <span>American Canyon</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/3853_Broadway_thumbnail.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/3853_Broadway_thumbnail.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/3853_Broadway_thumbnail.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 729000,
            "priceToShowStr": "$729,000",
            "squareFeet": 2430,
            "lotSize": ".56",
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        // {
        //     "ls": "",
        //     "lsLabelShort": "",
        //     "listingKey": "",
        //     "lid": null,
        //     "addressStr": "<span>229 Windjammer Dr</span> <span>Vallejo</span> <span>CA 00000</span>",
        //     "detailsURL": "",
        //     "detailsClientViewURL": "",
        //     "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/229_Windjammer_thumbnail.jpg",
        //     "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/229_Windjammer_thumbnail.jpg",
        //     "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/windjammer.jpg",
        //     "status": "Active",
        //     "listingClass": "",
        //     "priceToShow": 299000,
        //     "priceToShowStr": "$299,000",
        //     "squareFeet": 1168,
        //     "lotSize": null,
        //     "lotFeet": null,
        //     "lotAcres": null,
        //     "bedrooms": "2",
        //     "bathFull": "2",
        //     "bathPart": null,
        //     "bathAsStr": "2",
        //     "bathPartAsStr": null,
        //     "bathTotalAsStr": "2",
        //     "listDate": "",
        //     "expiresDate": "",
        //     "saleDate": "",
        //     "daysOnMarket": "",
        //     "age": null,
        //     "statusPresentation": "New",
        //     "virtualTourLink": "",
        //     "description": null,
        //     "county": null,
        //     "geoArea": null,
        //     "distance": null,
        //     "yearBuilt": null,
        //     "latitude": 37.9091254,
        //     "longitude": -122.1679971,
        //     "parkingTotal": null,
        //     "inFavorites": false,
        //     "featured": false,
        //     "officeListing": false,
        //     "newListing": false,
        //     "mostRecent": false,
        //     "openHouse": null,
        //     "codedParams": "0005606",
        //     "priceUp": false,
        //     "priceDown": false,
        //     "uniListingClass": "LOT",
        //     "pictures": [
        //         "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
        //     ],
        //     "disclosuresUrl": null,
        //     "onListRequired": null,
        //     "onThumbnailIconRequired": null,
        //     "onThumbnailRequired": null,
        //     "clientViewCount": null,
        //     "isClientDelete": null,
        //     "isRealtorDelete": null,
        //     "isClientWantToSee": null,
        //     "isClientWantMoreInfo": null,
        //     "clientLastSeeDate": null,
        //     "clientLastAppointmentDate": null,
        //     "clientLastMoreInfoDate": null,
        //     "favoriteSetUpDate": null
        // },
        // {
        //     "ls": "",
        //     "lsLabelShort": "",
        //     "listingKey": "",
        //     "lid": null,
        //     "addressStr": "<span>360 Sandstone Dr</span> <span>Vallejo</span> <span>CA 00000</span>",
        //     "detailsURL": "",
        //     "detailsClientViewURL": "",
        //     "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/360_Sandstone_thumbnail.jpg",
        //     "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/360_Sandstone_thumbnail.jpg",
        //     "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/360_Sandstone_thumbnail.jpg",
        //     "status": "Sold",
        //     "listingClass": "",
        //     "priceToShow": 289000,
        //     "priceToShowStr": "$289,000",
        //     "squareFeet": 1344,
        //     "lotSize": null,
        //     "lotFeet": 3029,
        //     "lotAcres": null,
        //     "bedrooms": "2",
        //     "bathFull": "2",
        //     "bathPart": null,
        //     "bathAsStr": "2",
        //     "bathPartAsStr": null,
        //     "bathTotalAsStr": "2",
        //     "listDate": "",
        //     "expiresDate": "",
        //     "saleDate": "",
        //     "daysOnMarket": "",
        //     "age": null,
        //     "statusPresentation": "New",
        //     "virtualTourLink": "",
        //     "description": null,
        //     "county": null,
        //     "geoArea": null,
        //     "distance": null,
        //     "yearBuilt": null,
        //     "latitude": 37.9091254,
        //     "longitude": -122.1679971,
        //     "parkingTotal": null,
        //     "inFavorites": false,
        //     "featured": false,
        //     "officeListing": false,
        //     "newListing": false,
        //     "mostRecent": false,
        //     "openHouse": null,
        //     "codedParams": "0005606",
        //     "priceUp": false,
        //     "priceDown": false,
        //     "uniListingClass": "LOT",
        //     "pictures": [
        //         "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
        //     ],
        //     "disclosuresUrl": null,
        //     "onListRequired": null,
        //     "onThumbnailIconRequired": null,
        //     "onThumbnailRequired": null,
        //     "clientViewCount": null,
        //     "isClientDelete": null,
        //     "isRealtorDelete": null,
        //     "isClientWantToSee": null,
        //     "isClientWantMoreInfo": null,
        //     "clientLastSeeDate": null,
        //     "clientLastAppointmentDate": null,
        //     "clientLastMoreInfoDate": null,
        //     "favoriteSetUpDate": null
        // },
        {
            "learnMore": false,
            "feature": true,
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>4680 Green Valley Lane</span> <span>Fairfield</span> <span>CA 00000</span>",
            "detailsURL": "https://www.4680greenvalley.com",
            "detailsClientViewURL": "https://www.4680greenvalley.com",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/THUMBNAIL.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/4680_green_valley.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/THUMBNAIL.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 1599000,
            "priceToShowStr": "$1,599,000",
            "squareFeet": null,
            "lotSize": ".94",
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": null,
            "bathTotalAsStr": "4",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "https://www.4680greenvalley.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "645044609204",
            "lid": null,
            "addressStr": "<span>29 Canyon View Drive</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/29-Canyon-View-Drive-Orinda-CA-94563/645044609204_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/29-Canyon-View-Drive-Orinda-CA-94563/645044609204_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "middleThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "status": "Active",
            "listingClass": "Acreage",
            "priceToShow": 2699000,
            "priceToShowStr": "$2,699,000",
            "squareFeet": null,
            "lotSize": ".94",
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "5",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": null,
            "bathTotalAsStr": "4",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "https://www.29canyonview.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "feature": true,
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641229",
            "lid": null,
            "addressStr": "<span>797 Moraga Rd</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/797-Moraga-Rd-Lafayette-CA-94549/12732641229_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/797-Moraga-Rd-Lafayette-CA-94549/12732641229_PM/0005601",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 1849000,
            "priceToShowStr": "$1,849,000",
            "squareFeet": "3300",
            "lotSize": ".9 acre",
            "lotFeet": null,
            "lotAcres": 0.9,
            "bedrooms": "4",
            "bathFull": "4.5",
            "bathPart": null,
            "bathAsStr": "4.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4.5",
            "listDate": "04/06/18",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "429",
            "age": null,
            "statusPresentation": "Active",
            "virtualTourLink": "https://www.797moragaroad.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "2005",
            "latitude": 37.8809745,
            "longitude": -122.1220412,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1134505914718.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40816518",
            "lid": "40816518",
            "addressStr": "<span>797 MORAGA RD</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/797-MORAGA-RD-Lafayette-CA-94549/40816518_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/797-MORAGA-RD-Lafayette-CA-94549/40816518_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1845000,
            "priceToShowStr": "$1,845,000",
            "squareFeet": "3300",
            "lotSize": "38000",
            "lotFeet": 38000,
            "lotAcres": 0.87,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "5",
            "listDate": "04/06/18",
            "expiresDate": "",
            "saleDate": "08/16/18",
            "daysOnMarket": "132",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.797moragaroad.com",
            "description": "A Custom built stunning estate beautifully detailed by an artistic Master Builder, Combining Old World and Neo-Classic Designs, exudes Charm, Intimacy and Character. This Estate is unparalleled in detail with Moldings, Columns, Fresco ceilings, Lighting, Travertine/Marble/Limestone Floors and, Architecturally worthy Designs. This Estate is on .87 of an acre of Roman Landscaping with fountains, Flowers, Trailing Plants, cypress Trees, A large upper flat-lawn area under a canopy of trees with a lovely Pavilion. An Inviting Spa, Atrium and Sunroom, A soothing waterfall cascades into the sunlit sparkling pool with a Cabana and Seating Area. Enjoy unobstructed views of the Lafayette Hills, Privacy and Serenity for Complete Relaxation and Enjoyment. Min. to Downtown and BART. Top rate Lafayette schools",
            "county": "CONTRA COSTA",
            "geoArea": "5100",
            "distance": null,
            "yearBuilt": "2003",
            "latitude": 37.8809745,
            "longitude": -122.1220412,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40696099",
            "lid": "40696099",
            "addressStr": "<span>49 Paseo Grande D</span> <span>San Lorenzo</span> <span>CA 94580</span>",
            "detailsURL": "https://agrealty1.com/IDX/49-Paseo-Grande-D-San-Lorenzo-CA-94580/40696099_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/49-Paseo-Grande-D-San-Lorenzo-CA-94580/40696099_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40696099/0/0/0/027eafb8d3bff7607693cc511e081d27/22/d4b0633a94cd46d8b5631f28fbedf3c7/40696099.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40696099/0/0/0/027eafb8d3bff7607693cc511e081d27/22/d4b0633a94cd46d8b5631f28fbedf3c7/40696099.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40696099/0/0/0/027eafb8d3bff7607693cc511e081d27/22/d4b0633a94cd46d8b5631f28fbedf3c7/40696099.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 352000,
            "priceToShowStr": "$352,000",
            "squareFeet": "1056",
            "lotSize": "0",
            "lotFeet": 0,
            "lotAcres": 0,
            "bedrooms": "2",
            "bathFull": "1",
            "bathPart": null,
            "bathAsStr": "1",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "2",
            "listDate": "04/24/15",
            "expiresDate": "",
            "saleDate": "05/28/15",
            "daysOnMarket": "34",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Perfect Location! Hard to find convenience to all freeways, yet private. Close to schools/shopping/restaurants. Warm setting with new paint, new stainless steel frig, washer/dryer, nice private patio, well lite, enclosed garage, offers due Tues. (May 5th) by 2 pm",
            "county": "ALAMEDA",
            "geoArea": "3200",
            "distance": null,
            "yearBuilt": "1985",
            "latitude": 37.6844996,
            "longitude": -122.1182259,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40696099/0/0/0/027eafb8d3bff7607693cc511e081d27/22/d4b0633a94cd46d8b5631f28fbedf3c7/40696099.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40670278",
            "lid": "40670278",
            "addressStr": "<span>4866 Starflower</span> <span>Martinez</span> <span>CA 94553</span>",
            "detailsURL": "https://agrealty1.com/IDX/4866-Starflower-Martinez-CA-94553/40670278_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/4866-Starflower-Martinez-CA-94553/40670278_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40670278/0/0/0/550a76bc54525add7ce890bac63ceb00/22/27dcd6d48bb8f9c134201bd3b6b7b3a6/40670278.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40670278/0/0/0/550a76bc54525add7ce890bac63ceb00/22/27dcd6d48bb8f9c134201bd3b6b7b3a6/40670278.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40670278/0/0/0/550a76bc54525add7ce890bac63ceb00/22/27dcd6d48bb8f9c134201bd3b6b7b3a6/40670278.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 385000,
            "priceToShowStr": "$385,000",
            "squareFeet": "1455",
            "lotSize": "2240",
            "lotFeet": 2240,
            "lotAcres": 0,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "08/15/14",
            "expiresDate": "",
            "saleDate": "10/02/14",
            "daysOnMarket": "48",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.4866starflower.com/",
            "description": "Great location for a starter home! New interior paint, new carpeting, ceiling fans, dual pane windows, wood burning fireplace, master suite on main upper level. Majestic views of Mt. Diablo and hills. Other amenities, community pool, kids playground, tennis court. Close to freeways, and shopping",
            "county": "CONTRA COSTA",
            "geoArea": "5601",
            "distance": null,
            "yearBuilt": "1984",
            "latitude": 37.9945834,
            "longitude": -122.0824352,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40670278/0/0/0/550a76bc54525add7ce890bac63ceb00/22/27dcd6d48bb8f9c134201bd3b6b7b3a6/40670278.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40668472",
            "lid": "40668472",
            "addressStr": "<span>54 HEATHER LN</span> <span>Orinda</span> <span>CA 94563-3519</span>",
            "detailsURL": "https://agrealty1.com/IDX/54-HEATHER-LN-Orinda-CA-94563-3519/40668472_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/54-HEATHER-LN-Orinda-CA-94563-3519/40668472_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1100000,
            "priceToShowStr": "$1,100,000",
            "squareFeet": "2105",
            "lotSize": "33600",
            "lotFeet": 33600,
            "lotAcres": 0.771,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "07/31/14",
            "expiresDate": "",
            "saleDate": "09/30/14",
            "daysOnMarket": "61",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Beautifully remodeled home with wonderful floor plan and majestic views of hills and Mt Diablo, Separate in-law 1 bed/1ba, large premium lot, quiet street and private. Just minutes to Meadow swim club, Glorietta Elementary, Downtown and Bart",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1967",
            "latitude": 37.8720655,
            "longitude": -122.1661566,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40676539",
            "lid": "40676539",
            "addressStr": "<span>87 LOMA VISTA DR</span> <span>Orinda</span> <span>CA 94563-2237</span>",
            "detailsURL": "https://agrealty1.com/IDX/87-LOMA-VISTA-DR-Orinda-CA-94563-2237/40676539_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/87-LOMA-VISTA-DR-Orinda-CA-94563-2237/40676539_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40676539/0/0/0/83083e5d0b234e7a69d34cc7fcfeb7e3/22/bd8918e1745a7a2774e26464ac18f673/40676539.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40676539/0/0/0/83083e5d0b234e7a69d34cc7fcfeb7e3/22/bd8918e1745a7a2774e26464ac18f673/40676539.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40676539/0/0/0/83083e5d0b234e7a69d34cc7fcfeb7e3/22/bd8918e1745a7a2774e26464ac18f673/40676539.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 699000,
            "priceToShowStr": "$699,000",
            "squareFeet": "1715",
            "lotSize": "21600",
            "lotFeet": 21600,
            "lotAcres": 0.496,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "10/06/14",
            "expiresDate": "",
            "saleDate": "11/30/14",
            "daysOnMarket": "55",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Charming cottage on 0.50 acre lot. Updated kitchen, original hardware, hardwood floors. In-law unit w/ kitchen,living rm, bed and bath. Unique opportunity to build another house or expand existing or enjoy as is or rent in-law. Great location",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1928",
            "latitude": 37.8830369,
            "longitude": -122.1992811,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40676539/0/0/0/83083e5d0b234e7a69d34cc7fcfeb7e3/22/bd8918e1745a7a2774e26464ac18f673/40676539.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40727067",
            "lid": "40727067",
            "addressStr": "<span>2958 BELLA DR</span> <span>Concord</span> <span>CA 94519</span>",
            "detailsURL": "https://agrealty1.com/IDX/2958-BELLA-DR-Concord-CA-94519/40727067_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/2958-BELLA-DR-Concord-CA-94519/40727067_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40727067/0/0/0/c4a583e8cfffcca8ee081b6136243031/22/f1ec15f382fdddf556a3c54cdb127a0e/40727067.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40727067/0/0/0/c4a583e8cfffcca8ee081b6136243031/22/f1ec15f382fdddf556a3c54cdb127a0e/40727067.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40727067/0/0/0/c4a583e8cfffcca8ee081b6136243031/22/f1ec15f382fdddf556a3c54cdb127a0e/40727067.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 589888,
            "priceToShowStr": "$589,888",
            "squareFeet": "1820",
            "lotSize": "1839",
            "lotFeet": 1839,
            "lotAcres": 0.042,
            "bedrooms": "3",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4",
            "listDate": "01/22/16",
            "expiresDate": "",
            "saleDate": "03/15/16",
            "daysOnMarket": "53",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Exceptional 6 year old home, stylish, contemporary, Culinary kitchen, high-end finishes, Solar energy, no electrical bills, hardwood floors, plantation shutters, short walk to BART, downtown, dining and shopping. No need to preview, it's gorgeous!",
            "county": "CONTRA COSTA",
            "geoArea": "5701",
            "distance": null,
            "yearBuilt": "2009",
            "latitude": 37.9751637,
            "longitude": -122.0242313,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40727067/0/0/0/c4a583e8cfffcca8ee081b6136243031/22/f1ec15f382fdddf556a3c54cdb127a0e/40727067.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40798330",
            "lid": "40798330",
            "addressStr": "<span>917 Lee Ln</span> <span>Concord</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/917-Lee-Ln-Concord-CA-94518/40798330_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/917-Lee-Ln-Concord-CA-94518/40798330_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1600000,
            "priceToShowStr": "$1,600,000",
            "squareFeet": "5040",
            "lotSize": "81022",
            "lotFeet": 81022,
            "lotAcres": 1.86,
            "bedrooms": "6",
            "bathFull": "5",
            "bathPart": null,
            "bathAsStr": "5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "6",
            "listDate": "09/25/17",
            "expiresDate": "",
            "saleDate": "08/17/18",
            "daysOnMarket": "326",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.917leelane.com",
            "description": "Wow! It is not very often that we find a property like this in the area.Experience Estate living at the end of quiet cul-de-sac bordering Walnut Creek and Pleasant Hill surrounded by other acreage estates in a prestigious neighborhood. Rebuilt 5040 squ. ft. from foundation up in 2006. Too much to list. MUST SEE! 3 minutes to all private schools and highly accredited Carondelet and De La salle high schools, BART, 24/680 freeways, shopping, restaurants, Iron Horse trail and night life. You will be amazed!",
            "county": "CONTRA COSTA",
            "geoArea": "5701",
            "distance": null,
            "yearBuilt": "2006",
            "latitude": 37.9394441,
            "longitude": -122.0401232,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40786622",
            "lid": "40786622",
            "addressStr": "<span>70 Forest Ridge Rd. 24</span> <span>Monterey</span> <span>CA 93940</span>",
            "detailsURL": "https://agrealty1.com/IDX/70-Forest-Ridge-Rd-24-Monterey-CA-93940/40786622_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/70-Forest-Ridge-Rd-24-Monterey-CA-93940/40786622_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40786622/0/0/0/ab926fd0b5ad331513f408494e654103/52/29c332cd15971337938fd5eeba13f3c4/40786622.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40786622/0/0/0/ab926fd0b5ad331513f408494e654103/52/29c332cd15971337938fd5eeba13f3c4/40786622.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40786622/0/0/0/ab926fd0b5ad331513f408494e654103/52/29c332cd15971337938fd5eeba13f3c4/40786622.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 595500,
            "priceToShowStr": "$595,500",
            "squareFeet": "1377",
            "lotSize": "803",
            "lotFeet": 803,
            "lotAcres": 0.018,
            "bedrooms": "2",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "06/22/17",
            "expiresDate": "",
            "saleDate": "09/21/17",
            "daysOnMarket": "91",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://my.matterport.com/models/ji9F5mKrfe8",
            "description": "Sweeping views of Monterey Bay. Totally remodeled and updated from top to bottom. It\u0092s Gorgeous with two master suites in a serene forest setting located in the Skyline Forest area and close to Monterey, Pebble Beach, and Pacific Grove . Includes all the furniture and accessories\u0085total luxury turnkey. Two car garage on street level. Main floor includes a lovely living room with a corner fireplace and views of Monterey Bay, a beautiful dining area, kitchen, washer/dryer, and half bath. Upstairs are two master suites with the large front master enjoying a sweeping view of the Monterey Bay. Best unit in the complex and centrally located with easy access to shopping/restaurants/highway. Less than 1/3 mile to Veteran's Memorial Park with over 50 acres. Watch the cruise ships from the Main floor or upstairs or take a wonderful walk through the park.",
            "county": "MONTEREY",
            "geoArea": "9915",
            "distance": null,
            "yearBuilt": "1977",
            "latitude": 36.597473,
            "longitude": -121.915729,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40786622/0/0/0/ab926fd0b5ad331513f408494e654103/52/29c332cd15971337938fd5eeba13f3c4/40786622.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40790249",
            "lid": "40790249",
            "addressStr": "<span>2335 BANBURY LOOP</span> <span>Martinez</span> <span>CA 94553</span>",
            "detailsURL": "https://agrealty1.com/IDX/2335-BANBURY-LOOP-Martinez-CA-94553/40790249_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/2335-BANBURY-LOOP-Martinez-CA-94553/40790249_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40790249/0/0/0/81b24feb38aaf0f078fab9a29b3d7dbe/52/df4baa2b9d0996849e332381acf7dd93/40790249.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40790249/0/0/0/81b24feb38aaf0f078fab9a29b3d7dbe/52/df4baa2b9d0996849e332381acf7dd93/40790249.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40790249/0/0/0/81b24feb38aaf0f078fab9a29b3d7dbe/52/df4baa2b9d0996849e332381acf7dd93/40790249.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 720000,
            "priceToShowStr": "$720,000",
            "squareFeet": "2153",
            "lotSize": "4950",
            "lotFeet": 4950,
            "lotAcres": 0.11,
            "bedrooms": "5",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "3",
            "listDate": "07/20/17",
            "expiresDate": "",
            "saleDate": "08/17/17",
            "daysOnMarket": "28",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.2335banbury.com",
            "description": "Turnkey stunning large home with high-end upgrades, total remodel, new exterior paint and roof. 1 bed with full bath on the first floor. 3 bed plus an office upstairs. Mini resort yard, low maintainance. Most desirable neighborhood in Shannon Hills. Martinez school district. NO NEED TO PREVIEW!",
            "county": "CONTRA COSTA",
            "geoArea": "5601",
            "distance": null,
            "yearBuilt": "1988",
            "latitude": 37.9995931,
            "longitude": -122.0854089,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40790249/0/0/0/81b24feb38aaf0f078fab9a29b3d7dbe/52/df4baa2b9d0996849e332381acf7dd93/40790249.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40647735",
            "lid": "40647735",
            "addressStr": "<span>1840 Tice Creek Drive 2114</span> <span>Walnut Creek</span> <span>CA 94595</span>",
            "detailsURL": "https://agrealty1.com/IDX/1840-Tice-Creek-Drive-2114-Walnut-Creek-CA-94595/40647735_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/1840-Tice-Creek-Drive-2114-Walnut-Creek-CA-94595/40647735_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40647735/0/0/0/d2e79f3058db5d5dcfe6363faa52c76e/22/58ddb5c294384e8f59da66bb1dfabb51/40647735.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40647735/0/0/0/d2e79f3058db5d5dcfe6363faa52c76e/22/58ddb5c294384e8f59da66bb1dfabb51/40647735.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40647735/0/0/0/d2e79f3058db5d5dcfe6363faa52c76e/22/58ddb5c294384e8f59da66bb1dfabb51/40647735.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 134500,
            "priceToShowStr": "$134,500",
            "squareFeet": "698",
            "lotSize": "0",
            "lotFeet": 0,
            "lotAcres": 0,
            "bedrooms": "1",
            "bathFull": "1",
            "bathPart": null,
            "bathAsStr": "1",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "1",
            "listDate": "02/28/14",
            "expiresDate": "",
            "saleDate": "03/28/14",
            "daysOnMarket": "28",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Exquisite Waterford turn key condo on the first floor with a nice patio enjoying North west exposure. Unit offers new carpets, new earth tone color paint and bath with new fixtures. Convenient to all the amenities, parking and transportation.",
            "county": "CONTRA COSTA",
            "geoArea": "5000",
            "distance": null,
            "yearBuilt": "1991",
            "latitude": 37.874179,
            "longitude": -122.076012,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40647735/0/0/0/d2e79f3058db5d5dcfe6363faa52c76e/22/58ddb5c294384e8f59da66bb1dfabb51/40647735.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40728920",
            "lid": "40728920",
            "addressStr": "<span>10 WHITEHALL DR</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/10-WHITEHALL-DR-Orinda-CA-94563/40728920_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/10-WHITEHALL-DR-Orinda-CA-94563/40728920_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1258000,
            "priceToShowStr": "$1,258,000",
            "squareFeet": "1848",
            "lotSize": "10168",
            "lotFeet": 10168,
            "lotAcres": 0.233,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "02/13/16",
            "expiresDate": "",
            "saleDate": "03/04/16",
            "daysOnMarket": "20",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.10whitehall.com",
            "description": "Lovely house in the \"Golden Triangle\", Walk to all 3 top rated schools, pride of ownership, new refinished hardwood floors throughout, double pane windows, many upgrades, secluded serene yard, one of the best locations. close to MV and MCC clubs, shopping and restaurants",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1959",
            "latitude": 37.8473108,
            "longitude": -122.1504555,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40744452",
            "lid": "40744452",
            "addressStr": "<span>419 Scottsdale Rd</span> <span>Pleasant Hill</span> <span>CA 94523</span>",
            "detailsURL": "https://agrealty1.com/IDX/419-Scottsdale-Rd-Pleasant-Hill-CA-94523/40744452_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/419-Scottsdale-Rd-Pleasant-Hill-CA-94523/40744452_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40744452/0/0/0/a3a2731f80900f341e31f030cc9a93c4/22/c5029d3e69ed042bdb1983361c51c783/40744452.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40744452/0/0/0/a3a2731f80900f341e31f030cc9a93c4/22/c5029d3e69ed042bdb1983361c51c783/40744452.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40744452/0/0/0/a3a2731f80900f341e31f030cc9a93c4/22/c5029d3e69ed042bdb1983361c51c783/40744452.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 460000,
            "priceToShowStr": "$460,000",
            "squareFeet": "1602",
            "lotSize": "1014",
            "lotFeet": 1014,
            "lotAcres": 0.02,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "06/10/16",
            "expiresDate": "",
            "saleDate": "09/02/16",
            "daysOnMarket": "84",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Lovely end unit townhouse in desirable Tres Lagos....Great location...quick to BART/Trader Joes/Target/Sams Club/Safeway/DVC/Sun Valley Mall/Restaurants. Vaulted Living room with fireplace. 1602 Sq.ft with 3 BR and 2 1/2 baths.",
            "county": "CONTRA COSTA",
            "geoArea": "5400",
            "distance": null,
            "yearBuilt": "1975",
            "latitude": 37.9764681,
            "longitude": -122.0735309,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40744452/0/0/0/a3a2731f80900f341e31f030cc9a93c4/22/c5029d3e69ed042bdb1983361c51c783/40744452.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40755913",
            "lid": "40755913",
            "addressStr": "<span>4 OVERHILL RD</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/4-OVERHILL-RD-Orinda-CA-94563/40755913_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/4-OVERHILL-RD-Orinda-CA-94563/40755913_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1175000,
            "priceToShowStr": "$1,175,000",
            "squareFeet": "2480",
            "lotSize": "17750",
            "lotFeet": 17750,
            "lotAcres": 0.407,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "09/05/16",
            "expiresDate": "",
            "saleDate": "10/14/16",
            "daysOnMarket": "39",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Dream, dream location. 2 min. walk to restaurants, Thetre and BART. Totally remodeled with custom kitchen, hardwood floors through out. Private yard with elegant entry. Every room is bright, light and overlooks garden. Extra storage, workshop, large garage, outdoot hot tub. Top rated schools.",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1957",
            "latitude": 37.8763199,
            "longitude": -122.178707,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40815258",
            "lid": "40815258",
            "addressStr": "<span>143 Lynn Dr</span> <span>Brentwood</span> <span>CA 94513</span>",
            "detailsURL": "https://agrealty1.com/IDX/143-Lynn-Dr-Brentwood-CA-94513/40815258_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/143-Lynn-Dr-Brentwood-CA-94513/40815258_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40815258/0/0/0/5befcbadd66eef0ed4c971a4c67f1cd1/68/6239cc5a6a2b19eb5ae68d070524d71c/40815258.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40815258/0/0/0/5befcbadd66eef0ed4c971a4c67f1cd1/68/6239cc5a6a2b19eb5ae68d070524d71c/40815258.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40815258/0/0/0/5befcbadd66eef0ed4c971a4c67f1cd1/68/6239cc5a6a2b19eb5ae68d070524d71c/40815258.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 410000,
            "priceToShowStr": "$410,000",
            "squareFeet": "1349",
            "lotSize": "5000",
            "lotFeet": 5000,
            "lotAcres": 0.12,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "03/27/18",
            "expiresDate": "",
            "saleDate": "06/25/18",
            "daysOnMarket": "90",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.allaccessphoto.com/cgi-bin/user_add_info_complete.pl#",
            "description": "Beautiful floor plan, inviting spaces, incredible huge Florida sunroom access from family room, perfect for entertaining all year around. Quiet street, close to downtown, shopping and restaurants",
            "county": "CONTRA COSTA",
            "geoArea": "6400",
            "distance": null,
            "yearBuilt": "1965",
            "latitude": 37.9411103,
            "longitude": -121.6984586,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40815258/0/0/0/5befcbadd66eef0ed4c971a4c67f1cd1/68/6239cc5a6a2b19eb5ae68d070524d71c/40815258.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40812064",
            "lid": "40812064",
            "addressStr": "<span>192 Cabana Court</span> <span>Tracy</span> <span>CA 95377-1134</span>",
            "detailsURL": "https://agrealty1.com/IDX/192-Cabana-Court-Tracy-CA-95377-1134/40812064_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/192-Cabana-Court-Tracy-CA-95377-1134/40812064_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40812064/0/0/0/0fce1bf31058424adb3db70c34c62a9f/68/9f28348abba62598ef89a4ed9faa813b/40812064.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40812064/0/0/0/0fce1bf31058424adb3db70c34c62a9f/68/9f28348abba62598ef89a4ed9faa813b/40812064.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40812064/0/0/0/0fce1bf31058424adb3db70c34c62a9f/68/9f28348abba62598ef89a4ed9faa813b/40812064.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 519000,
            "priceToShowStr": "$519,000",
            "squareFeet": "2396",
            "lotSize": "5040",
            "lotFeet": 5040,
            "lotAcres": 1,
            "bedrooms": "4",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "02/28/18",
            "expiresDate": "",
            "saleDate": "07/17/18",
            "daysOnMarket": "139",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "This listing is only for comparables information",
            "county": "SAN JOAQUIN",
            "geoArea": "9901",
            "distance": null,
            "yearBuilt": "2003",
            "latitude": 37.7271173,
            "longitude": -121.4543443,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40812064/0/0/0/0fce1bf31058424adb3db70c34c62a9f/68/9f28348abba62598ef89a4ed9faa813b/40812064.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40804673",
            "lid": "40804673",
            "addressStr": "<span>718 E 24th</span> <span>Oakland</span> <span>CA 94606-2043</span>",
            "detailsURL": "https://agrealty1.com/IDX/718-E-24th-Oakland-CA-94606-2043/40804673_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/718-E-24th-Oakland-CA-94606-2043/40804673_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
            "status": "Sold",
            "listingClass": "Residential Income",
            "priceToShow": 880000,
            "priceToShowStr": "$880,000",
            "squareFeet": "3120",
            "lotSize": "5000",
            "lotFeet": 5000,
            "lotAcres": 0.12,
            "bedrooms": null,
            "bathFull": null,
            "bathPart": null,
            "bathAsStr": null,
            "bathPartAsStr": null,
            "bathTotalAsStr": "0",
            "listDate": "11/29/17",
            "expiresDate": "",
            "saleDate": "01/02/18",
            "daysOnMarket": "34",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Rare opportunity to own this 4 unit property located in the highly desirable Ivy Hill neighborhood. Spacious units with separate dining room. Front units have city light views. Coin-operated laundry site in basement for additional income. While being close to Lake Merritt you can also take advantage of the nearby dining and retail scene taking place on Grand Avenue and Lakeshore Avenue, just minutes away. Bart, AC transit lines, I-880 and I-580 are all with-in minutes of access. Huge upside in rents.",
            "county": "ALAMEDA",
            "geoArea": "2606",
            "distance": null,
            "yearBuilt": "1941",
            "latitude": 37.800603,
            "longitude": -122.242259,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "MUL",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40808980",
            "lid": "40808980",
            "addressStr": "<span>3736 Chesapeake Ct</span> <span>Antioch</span> <span>CA 94509</span>",
            "detailsURL": "https://agrealty1.com/IDX/3736-Chesapeake-Ct-Antioch-CA-94509/40808980_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/3736-Chesapeake-Ct-Antioch-CA-94509/40808980_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40808980/0/0/0/7fd5cb2cce38d51c48fac493843233d3/68/1729473229dfd8fa3fc7eebf72761e83/40808980.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40808980/0/0/0/7fd5cb2cce38d51c48fac493843233d3/68/1729473229dfd8fa3fc7eebf72761e83/40808980.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40808980/0/0/0/7fd5cb2cce38d51c48fac493843233d3/68/1729473229dfd8fa3fc7eebf72761e83/40808980.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 470000,
            "priceToShowStr": "$470,000",
            "squareFeet": "2135",
            "lotSize": "3983",
            "lotFeet": 3983,
            "lotAcres": 0.09,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "3",
            "listDate": "01/31/18",
            "expiresDate": "",
            "saleDate": "03/08/18",
            "daysOnMarket": "36",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Well-maintained, Lovely Two-Story Spacious Home, Updated Kitchen with Granite Counter Tops, Open to Family Room, with Fireplace with Gas Starter, Bedroom and Bath downstairs, Luxurious Large Master Suite Bedroom with Separate Tub and Shower in Master Bath, Inside Laundry, Vaulted Ceilings, All New Interior Paint, Great Cul-De-Sac Location. No Need to Preview",
            "county": "CONTRA COSTA",
            "geoArea": "6200",
            "distance": null,
            "yearBuilt": "1989",
            "latitude": 37.9839087,
            "longitude": -121.8270339,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40808980/0/0/0/7fd5cb2cce38d51c48fac493843233d3/68/1729473229dfd8fa3fc7eebf72761e83/40808980.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40767724",
            "lid": "40767724",
            "addressStr": "<span>W Clover Rd</span> <span>Tracy</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/W-Clover-Rd-Tracy-CA-94518/40767724_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/W-Clover-Rd-Tracy-CA-94518/40767724_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "status": "Sold",
            "listingClass": "Commercial Lots and Land",
            "priceToShow": 875000,
            "priceToShowStr": "$875,000",
            "squareFeet": null,
            "lotSize": "83208",
            "lotFeet": 83208,
            "lotAcres": 1.91,
            "bedrooms": null,
            "bathFull": null,
            "bathPart": null,
            "bathAsStr": null,
            "bathPartAsStr": null,
            "bathTotalAsStr": "0",
            "listDate": "01/17/17",
            "expiresDate": "",
            "saleDate": "05/02/18",
            "daysOnMarket": "470",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Was approved for strip shopping center. Commercial PUD zoning. Suitable for various uses as long as one follows code for Highway commercial zoning. City will not allow any business with noise pollution like auto repair. Behind In and Out and fast food restaurants, busy intersection, visibility from Fwy 205, all flat rectangular lot.",
            "county": "SAN JOAQUIN",
            "geoArea": "9915",
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.7614038,
            "longitude": -121.4372004,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "COM",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "feature": false,
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40801144",
            "lid": "40801144",
            "addressStr": "<span>51 Martha Rd</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/51-Martha-Rd-Orinda-CA-94563/40801144_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/51-Martha-Rd-Orinda-CA-94563/40801144_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1850000,
            "priceToShowStr": "$1,850,000",
            "squareFeet": "2956",
            "lotSize": "35000",
            "lotFeet": 35000,
            "lotAcres": 0.8,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "4",
            "listDate": "10/19/17",
            "expiresDate": "",
            "saleDate": "11/17/17",
            "daysOnMarket": "29",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Amazing well-loved home, first time on the market by Original owners, very gracious living spaces, distinctive Tudor, fabulous great room, updated kitchen, conservatory sun room, Bath spa, hardwood floors, 2 fireplaces, beautiful private back yard, Perfect set up for in-law quarters, so many amenities ... too much to mention. 2 min. to Glorietta Elementary, 5 min. to Downtown, BART and HWY 24 One of the most desirable neighborhoods of Glorietta area. Top rated schools",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1977",
            "latitude": 37.8732842,
            "longitude": -122.1668202,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40610615",
            "lid": "40610615",
            "addressStr": "<span>790 Quiet View Ct.</span> <span>Walnut Creek</span> <span>CA 94597</span>",
            "detailsURL": "https://agrealty1.com/IDX/790-Quiet-View-Ct-Walnut-Creek-CA-94597/40610615_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/790-Quiet-View-Ct-Walnut-Creek-CA-94597/40610615_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40610615/0/0/0/c9375e218f3cef65edda3e9a7da42328/22/25a22b95af15e05444cb4083bf09447c/40610615.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40610615/0/0/0/c9375e218f3cef65edda3e9a7da42328/22/25a22b95af15e05444cb4083bf09447c/40610615.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40610615/0/0/0/c9375e218f3cef65edda3e9a7da42328/22/25a22b95af15e05444cb4083bf09447c/40610615.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 533000,
            "priceToShowStr": "$533,000",
            "squareFeet": "1673",
            "lotSize": "11830",
            "lotFeet": 11830,
            "lotAcres": 0.27,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "04/13/13",
            "expiresDate": "",
            "saleDate": "07/25/13",
            "daysOnMarket": "103",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "No more offers. Offer is in judges hand. Pending soon. Cutoff was April 22 and 25 offers were made.",
            "county": "CONTRA COSTA",
            "geoArea": "4900",
            "distance": null,
            "yearBuilt": "1961",
            "latitude": 37.9215829,
            "longitude": -122.0755109,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40610615/0/0/0/c9375e218f3cef65edda3e9a7da42328/22/25a22b95af15e05444cb4083bf09447c/40610615.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40649892",
            "lid": "40649892",
            "addressStr": "<span>35 VILLAGE SQUARE PL</span> <span>Pleasant Hill</span> <span>CA 94523-4388</span>",
            "detailsURL": "https://agrealty1.com/IDX/35-VILLAGE-SQUARE-PL-Pleasant-Hill-CA-94523-4388/40649892_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/35-VILLAGE-SQUARE-PL-Pleasant-Hill-CA-94523-4388/40649892_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40649892/0/0/0/d97dc736319177ad614221d1f6a9a882/22/01a8c420bef0f7c8dd3a139a7a62d94e/40649892.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40649892/0/0/0/d97dc736319177ad614221d1f6a9a882/22/01a8c420bef0f7c8dd3a139a7a62d94e/40649892.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40649892/0/0/0/d97dc736319177ad614221d1f6a9a882/22/01a8c420bef0f7c8dd3a139a7a62d94e/40649892.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 575000,
            "priceToShowStr": "$575,000",
            "squareFeet": "2187",
            "lotSize": "3280",
            "lotFeet": 3280,
            "lotAcres": 0.075,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4",
            "listDate": "03/19/14",
            "expiresDate": "",
            "saleDate": "05/09/14",
            "daysOnMarket": "51",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Striking contemporary, Big loft, vaulted ceilings, open, airy, bright, quiet. 2 master suites upstairs and downstairs, new cherry wood floors, new carpets in all bedrooms, new frig. Walking distance to shopping centers, restaurants, Pleasant Hill Bart station and easy access to 680 Freeway.",
            "county": "CONTRA COSTA",
            "geoArea": "5400",
            "distance": null,
            "yearBuilt": "1999",
            "latitude": 37.9398457,
            "longitude": -122.0547356,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40649892/0/0/0/d97dc736319177ad614221d1f6a9a882/22/01a8c420bef0f7c8dd3a139a7a62d94e/40649892.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40651068",
            "lid": "40651068",
            "addressStr": "<span>11502 Silvergate Dr</span> <span>Dublin</span> <span>CA 94568</span>",
            "detailsURL": "https://agrealty1.com/IDX/11502-Silvergate-Dr-Dublin-CA-94568/40651068_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/11502-Silvergate-Dr-Dublin-CA-94568/40651068_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40651068/0/0/0/07b340b38976076ffeea5c7be8ad07e0/22/c7e948ac137bea5172262c24028ed18c/40651068.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40651068/0/0/0/07b340b38976076ffeea5c7be8ad07e0/22/c7e948ac137bea5172262c24028ed18c/40651068.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40651068/0/0/0/07b340b38976076ffeea5c7be8ad07e0/22/c7e948ac137bea5172262c24028ed18c/40651068.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 705000,
            "priceToShowStr": "$705,000",
            "squareFeet": "1649",
            "lotSize": "9128",
            "lotFeet": 9128,
            "lotAcres": 0.209,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "03/27/14",
            "expiresDate": "",
            "saleDate": "05/06/14",
            "daysOnMarket": "40",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Beautiful Home in the desirable Briar Hill\u0092s section of West Dublin. Convenient to downtown, restaurants, BART, freeway. Brand new roof. Wood floors throughout. Great floor plan on an elevated corner lot. Gorgeous olive tree in front. Backyard privacy-plenty of parking. No more offers taken.",
            "county": "ALAMEDA",
            "geoArea": "4100",
            "distance": null,
            "yearBuilt": "1966",
            "latitude": 37.7025871,
            "longitude": -121.9461778,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40651068/0/0/0/07b340b38976076ffeea5c7be8ad07e0/22/c7e948ac137bea5172262c24028ed18c/40651068.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40683752",
            "lid": "40683752",
            "addressStr": "<span>13 VIRGINIA DR</span> <span>Orinda</span> <span>CA 94563-3514</span>",
            "detailsURL": "https://agrealty1.com/IDX/13-VIRGINIA-DR-Orinda-CA-94563-3514/40683752_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/13-VIRGINIA-DR-Orinda-CA-94563-3514/40683752_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 3375000,
            "priceToShowStr": "$3,375,000",
            "squareFeet": "7848",
            "lotSize": "69696",
            "lotFeet": 69696,
            "lotAcres": 1.6,
            "bedrooms": "5",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "5",
            "listDate": "01/10/15",
            "expiresDate": "",
            "saleDate": "02/26/15",
            "daysOnMarket": "47",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Dramatic SINGLE STORY Contemporary estate offers luxury life style, Recreation Room downstairs only, private lush grounds, large pool, outdoor kitchen, Guest cottage, Fitness center, Tennis court, Putting green and Playground. Ideal location, short distance to schools, freeway and downtown",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1986",
            "latitude": 37.8672945,
            "longitude": -122.1623202,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40655902",
            "lid": "40655902",
            "addressStr": "<span>39 VILLAGE SQUARE PL</span> <span>Pleasant Hill</span> <span>CA 94523-4388</span>",
            "detailsURL": "https://agrealty1.com/IDX/39-VILLAGE-SQUARE-PL-Pleasant-Hill-CA-94523-4388/40655902_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/39-VILLAGE-SQUARE-PL-Pleasant-Hill-CA-94523-4388/40655902_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40655902/0/0/0/0893731033d0daf829b195b308258550/22/b6a32dedae80dd0c8d2900fb4d9af838/40655902.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40655902/0/0/0/0893731033d0daf829b195b308258550/22/b6a32dedae80dd0c8d2900fb4d9af838/40655902.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40655902/0/0/0/0893731033d0daf829b195b308258550/22/b6a32dedae80dd0c8d2900fb4d9af838/40655902.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 583000,
            "priceToShowStr": "$583,000",
            "squareFeet": "1690",
            "lotSize": "2300",
            "lotFeet": 2300,
            "lotAcres": 0.053,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "05/02/14",
            "expiresDate": "",
            "saleDate": "06/05/14",
            "daysOnMarket": "34",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Lovely contemporary home with a great floor plan, open, bright and airy. A happy home. Will impress your client. Pristine condition. great location, walk to Bart, shopping, restaurants. Easy access to freeway. Pleasant hill schools.",
            "county": "CONTRA COSTA",
            "geoArea": "5400",
            "distance": null,
            "yearBuilt": "1999",
            "latitude": 37.939829,
            "longitude": -122.0549132,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40655902/0/0/0/0893731033d0daf829b195b308258550/22/b6a32dedae80dd0c8d2900fb4d9af838/40655902.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40658536",
            "lid": "40658536",
            "addressStr": "<span>12858 Manna Lane</span> <span>Loma Rica</span> <span>CA 95901</span>",
            "detailsURL": "https://agrealty1.com/IDX/12858-Manna-Lane-Loma-Rica-CA-95901/40658536_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/12858-Manna-Lane-Loma-Rica-CA-95901/40658536_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40658536/0/0/0/ac414b50c802787fc0c2c1fa54dd93e7/22/21d5ebdc288576d5655ce014749f088b/40658536.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40658536/0/0/0/ac414b50c802787fc0c2c1fa54dd93e7/22/21d5ebdc288576d5655ce014749f088b/40658536.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40658536/0/0/0/ac414b50c802787fc0c2c1fa54dd93e7/22/21d5ebdc288576d5655ce014749f088b/40658536.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 679000,
            "priceToShowStr": "$679,000",
            "squareFeet": "3000",
            "lotSize": "439520",
            "lotFeet": 439520,
            "lotAcres": 10.09,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4",
            "listDate": "05/20/14",
            "expiresDate": "",
            "saleDate": "09/24/14",
            "daysOnMarket": "127",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.foothillscountryliving.com",
            "description": "Beautiful traditional home, vaulted ceilings, granite counter tops,Pond, lake size, Creek, two horse stable, garage, rumpus secluded, paved driveway with historic strt lamps,lakeside arbor, outdoors Jacuzzi,Completely fenced with horse pastures, half an hour from Marysville, one hour from Sacramento",
            "county": "YUBA",
            "geoArea": "9915",
            "distance": null,
            "yearBuilt": "1984",
            "latitude": 39.333284,
            "longitude": -121.453452,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40658536/0/0/0/ac414b50c802787fc0c2c1fa54dd93e7/22/21d5ebdc288576d5655ce014749f088b/40658536.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40687118",
            "lid": "40687118",
            "addressStr": "<span>1743 Carmel Drive 12</span> <span>Walnut Creek</span> <span>CA 94596</span>",
            "detailsURL": "https://agrealty1.com/IDX/1743-Carmel-Drive-12-Walnut-Creek-CA-94596/40687118_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/1743-Carmel-Drive-12-Walnut-Creek-CA-94596/40687118_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40687118/0/0/0/ae4813025f1d87a1e11c8c2f20b6e96f/22/342242d04d5c071ac69c654e65b6b0f6/40687118.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40687118/0/0/0/ae4813025f1d87a1e11c8c2f20b6e96f/22/342242d04d5c071ac69c654e65b6b0f6/40687118.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40687118/0/0/0/ae4813025f1d87a1e11c8c2f20b6e96f/22/342242d04d5c071ac69c654e65b6b0f6/40687118.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 405000,
            "priceToShowStr": "$405,000",
            "squareFeet": "896",
            "lotSize": "896",
            "lotFeet": 896,
            "lotAcres": 0,
            "bedrooms": "2",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "02/13/15",
            "expiresDate": "",
            "saleDate": "03/20/15",
            "daysOnMarket": "35",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Resplendent! This condo is totally remodeled with wood floors, granite, crown moldings, earth tone colors, private patio. one can not ask for better location. Walk to Broadway plaza, restaurants, night life, library, Civic park and Bart. No need to preview. Just show it to your clients.",
            "county": "CONTRA COSTA",
            "geoArea": "4900",
            "distance": null,
            "yearBuilt": "1966",
            "latitude": 37.9025049,
            "longitude": -122.0583094,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40687118/0/0/0/ae4813025f1d87a1e11c8c2f20b6e96f/22/342242d04d5c071ac69c654e65b6b0f6/40687118.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40685646",
            "lid": "40685646",
            "addressStr": "<span>349 Scottsdale Rd</span> <span>Pleasant Hill</span> <span>CA 94523</span>",
            "detailsURL": "https://agrealty1.com/IDX/349-Scottsdale-Rd-Pleasant-Hill-CA-94523/40685646_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/349-Scottsdale-Rd-Pleasant-Hill-CA-94523/40685646_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40685646/0/0/0/9988d2f259ec87813e5762db056ae141/22/60610537710d0ab8fadb0de24c4f2731/40685646.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40685646/0/0/0/9988d2f259ec87813e5762db056ae141/22/60610537710d0ab8fadb0de24c4f2731/40685646.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40685646/0/0/0/9988d2f259ec87813e5762db056ae141/22/60610537710d0ab8fadb0de24c4f2731/40685646.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 442000,
            "priceToShowStr": "$442,000",
            "squareFeet": "1583",
            "lotSize": "0",
            "lotFeet": 0,
            "lotAcres": 0,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "01/30/15",
            "expiresDate": "",
            "saleDate": "03/16/15",
            "daysOnMarket": "45",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Close to EVERYTHING! Wonderful townhouse with lots of space. Look out to large front yard area. Charming enclosed patio. Nearby walk around lake. Open House Sat and Sun 1-4",
            "county": "CONTRA COSTA",
            "geoArea": "5400",
            "distance": null,
            "yearBuilt": "1982",
            "latitude": 37.977732,
            "longitude": -122.072729,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40685646/0/0/0/9988d2f259ec87813e5762db056ae141/22/60610537710d0ab8fadb0de24c4f2731/40685646.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40716075",
            "lid": "40716075",
            "addressStr": "<span>1703 San Andres Dr.</span> <span>Pittsburg</span> <span>CA 94565</span>",
            "detailsURL": "https://agrealty1.com/IDX/1703-San-Andres-Dr-Pittsburg-CA-94565/40716075_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/1703-San-Andres-Dr-Pittsburg-CA-94565/40716075_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40716075/0/0/0/d1b956c336bff5cb8e4e34fe1e334452/22/8867f9fba66987e6408c0aac664ee250/40716075.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40716075/0/0/0/d1b956c336bff5cb8e4e34fe1e334452/22/8867f9fba66987e6408c0aac664ee250/40716075.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40716075/0/0/0/d1b956c336bff5cb8e4e34fe1e334452/22/8867f9fba66987e6408c0aac664ee250/40716075.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 490000,
            "priceToShowStr": "$490,000",
            "squareFeet": "2654",
            "lotSize": "6180",
            "lotFeet": 6180,
            "lotAcres": 0.142,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "3",
            "listDate": "09/17/15",
            "expiresDate": "",
            "saleDate": "01/06/16",
            "daysOnMarket": "111",
            "age": null,
            "statusPresentation": "Sold-Short Sale",
            "virtualTourLink": null,
            "description": "Extremely nice 4 BR 3 BA. 5th BR opened to large Master Suite. Located in desirable San Marco neighborhood. Close to schools and BART. Easy freeway access at Bay Point exit. Lots of extras. Huge garage, loft area, corner lot. Now pending.",
            "county": "CONTRA COSTA",
            "geoArea": "6100",
            "distance": null,
            "yearBuilt": "2004",
            "latitude": 38.0131174,
            "longitude": -121.9641524,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40716075/0/0/0/d1b956c336bff5cb8e4e34fe1e334452/22/8867f9fba66987e6408c0aac664ee250/40716075.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "Core Vision Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        }
    ],
    "title": "Your Real Estate Results",
    "favoritesCount": 0
}