import React, { Component } from 'react'
import { Form, Message, Menu, Grid, Card, Segment, Container, Header, Image, Button, Icon } from 'semantic-ui-react'
import request from 'request-promise';
import _ from 'lodash';
import './App.css';


class FormExampleSubcomponentControl extends Component {
  state = {
    values: { email: '', name: '', message: '' },
    submitted: false
  }

  handleChange = (inputType) => (e, { value }) => this.setState({ values: {
    email: inputType == 'email'? value : this.state.values.email,
    name: inputType == 'name' ? value : this.state.values.name,
    message: inputType == 'message' ? value : this.state.values.message
  } });

  send = () => {

    const options = { method: 'POST',
      url: 'https://hjbz2j5zs8.execute-api.us-east-1.amazonaws.com/v1/email',
      headers:
       { 'content-type': 'application/json'
        },
      body: this.state.values,
      json: true
    };

    request(options)
    .then((result) => {
      console.log("Send Success")
      this.setState({
        values: { email: '', name: '', message: '' },
        submitted: true
      })
      setTimeout(() => this.setState({submitted: false}), 15000);
    })
    .catch((error) => {
      console.log("Unsuccesful");
      console.log(error)
    })

  }

  buttonDisabled = () => {
    let disabled = false;
    for (let key in this.state.values) {
      console.log(this.state.values[key])
      if (this.state.values[key].length == 0)
        return true;
    }
    return false;
  }

  render() {
    const { value } = this.state
    return (
      <Form style={{width: '80%', marginTop: '100px'}}>
        <Form.Group widths='equal'>
          <Form.Input value={this.state.values.email} onChange={this.handleChange('email')} fluid label='Email' placeholder='sample@gmail.com' />
        </Form.Group>
        <Form.Group widths='equal'>
          <Form.Input value={this.state.values.name} onChange={this.handleChange('name')} fluid label='Name' placeholder='First, Last' />
        </Form.Group>
        <Form.TextArea value={this.state.values.message} onChange={this.handleChange('message')} label='Body' placeholder='Tell us more about you...' style={{height:'300px'}}/>
        <Form.Button disabled={this.buttonDisabled()} onClick={this.send}>Submit</Form.Button>
       <Message success visible={this.state.submitted} header='Form Submitted' content="Thank you for your enquiry with AG Realty. We will respond shortly." />
      </Form>

    )
  }
}

class Contact extends Component {
  state = { }

  render(){

    return (
      <div style={{
        position: 'relative',
        marginTop: '25px'
      }}>
        <img src = 'https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/kitchen1.jpg' style={{
          width:'100%',
          zIndex: -2,
          minHeight:'520px',
          position: 'absolute',
          left: '0px',
          top: '0px',
          height: '700px'
        }} />
        <div className="contactForm" style={{
          zIndex: -1,
          minHeight:'520px',
          position: 'absolute',
          top: '0px',
          left: '0px',
          backgroundColor: 'white',
          opacity:'0.9',
          height: '700px',
          justifyContent: 'center',
          display: 'flex'
        }}>

        </div>

        <div className="contactForm" style={{
          textAlign: 'left',
          height: '700px',
          justifyContent: 'center',
          left:'0px',
          display: 'flex'
        }}>
          <FormExampleSubcomponentControl />
        </div>

      </div>
    );
  }
}


export default Contact;