import React, { Component } from 'react';
import request from 'request-promise';
import _ from 'lodash';
import Hero from 'react-lazy-hero';
import { Dropdown, Menu, Grid, Card, Segment, Container, Header, Image, Button, Icon } from 'semantic-ui-react'
import logo from './logo.svg';
import Slider from "react-slick";
import MeetTheTeam from './meetTheTeam';
import Services from './services';
import Contact from './contact';
import Listings from './Listings';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './App.css';
import searchByActive from './searchByActive.js';

const blacklist = [
  "13 Virginia",
  "29 Canyon"
];

class SimpleSlider extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {

    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }


  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    };

    const testimonials = [
      {
        quote: "... Marketing genius, perfect Realtors, charming, kind and helpful ..." ,
        name: "Catherine Rice Berger",
      },
      {
        quote: "Alex and Katia did an excellent and thorough job as a realtor team. ... I highly recommend them to anyone looking to buy or sell a home!",
        name: "Eileen Shalk"
      },
      {
        quote: "... Uniquely talented, assertive, competent, consummate professionals ... ",
        name: "Maureen Behrendt"
      },{
        quote: "... in love with their work, infectious sense of humors, the magicians, good listeners ... ",
        name: "Laura Wills Mooney"
      },{
        quote: "... Professional, trustworthy, extremely hard working ... ",
        name: "Helen Hofmann  "
      },{
        quote: "... Look no further when looking for a listing agent, Alex is your guy. His marketing ability was outstanding and his years of experience shows!! ... ",
        name: "Robyn S"
      },{
        quote: "As a first time estate seller you made it so easy and simple. You have a great way with what you do, selling houses. You made us feel comfortable and we trusted your every move. We would highly recommend Alex for any of you house needs, very professional and easy to work with.",
        name: "Kristin Baxter  "
      },{
        quote: "Orinda-based Alexander Gailas of AG Realty is one of the best realtors I've met. His professional demeanor, calm and centered composure, his work ethic, and his commitment to his clients are stellar, and I hope others avail themselves to his expertise, and his warm hearted enthusiasm to his profession.",
        name: "Bendrew Jong"
      },{
        quote: "Their contacts with contractors, staging people and others in the community made the critical difference in selling my home of 25 years in Orinda….Treat you like a neighbor rather than just another client—always there to do whatever it takes to solve problems in a dynamic market.",
        name: "Jim Malot"
      },
      {
        quote: "... by far the most professional, dedicated agents we’ve ever had ...",
        name: "Dennis and Maile Alfarot"
      },
      {
        quote: "... Integrity, creativity, and non stop energy .. ",
        name: "Gloria Brown Brobeck"
      }
    ]

    let testimonialsIterator = [];
    for(var i = 0; i < testimonials.length; i += 3) {
      testimonialsIterator.push(testimonials.slice(i, i+3));
    }
    let { width } = this.state;
    console.log(width)
    return (
      <div style={{
        marginTop: '150px',
      }}>
        <div style={{
          position:'relative'
        }}>
        <div style={{width:'100%',
                position:'absolute',
                backgroundColor:'white',
                top:'0px',
                height:'110%'
              }}>
          <img src = 'https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/couch.jpg' style={{
            width:'100%',
            zIndex: -2,
            opacity:0.1,
            minHeight:'680px'
          }} />
          <h2 className="sectionHead" style={{
                position: 'absolute',
                top: '30px',
              }}>Testimonials</h2>
        </div>
        <div >
        {( () => {
          if (width < 801)
            return (
              <div className="mobileSlider">
               <Slider {...settings}>
              { testimonials.map((quote) => (
                <div>
                        <Grid centered style={{
                          marginTop: '170px',
                          marginBottom:'40px',
                          marginLeft: '0em !important',
                          marginRight: '0em !important'
                        }}>
                          <Grid.Row centered columns={1}>
                              <Grid.Column centered>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex'
                                }}>
                                  <h3 className='headerstyles'>{quote.name}</h3>
                                </div>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '30px'
                                }}>
                                  <p className='quoteStyles' style={{width:'200%!important'}}>{quote.quote}</p>
                                </div>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '50px'
                                }}>
                                </div>
                              </Grid.Column>
                          </Grid.Row>
                          <Grid.Row centered columns={1}>
                              <Grid.Column centered>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '20px'
                                }}>
                                  <img src='./quotes2.png' style={{height:'40px'}}/>
                                </div>
                              </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </div>))}
                  </Slider>
                </div>
              );
            else
              return (
                <div className="desktopSlider">
                  <Slider {...settings}>
                    { testimonialsIterator.map((chunk) => (
                      <div>
                        <Grid centered style={{
                          marginTop: '170px',
                          marginBottom:'40px',
                          marginLeft: '0em !important',
                          marginRight: '0em !important'
                        }}>
                          <Grid.Row centered columns={3}>
                            {chunk.map((quote) =>
                              <Grid.Column centered>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex'
                                }}>
                                  <h3 className='headerstyles'>{quote.name}</h3>
                                </div>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '30px'
                                }}>
                                  <p className='quoteStyles'>{quote.quote}</p>
                                </div>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '50px'
                                }}>
                                </div>
                              </Grid.Column>)}
                          </Grid.Row>
                          <Grid.Row centered columns={3}>
                            {chunk.map((quote) =>
                              <Grid.Column centered>
                                <div style={{
                                  justifyContent: 'center',
                                  display: 'flex',
                                  marginTop: '20px'
                                }}>
                                  <img src='./quotes2.png' style={{height:'40px'}}/>
                                </div>
                              </Grid.Column>)}
                          </Grid.Row>
                        </Grid>
                      </div>))}
                    </Slider>
                  </div> );
        })()}
        </div>
        </div>
      </div>
    );
  }
}

class Featured extends Component {
  state = { loading: true, listings:[] }

  componentDidMount(){

    const result = searchByActive
    console.log(result.listings);
    //Exctract address
    result.listings.map(listing => {listing.addr = this.getAddress(listing.addressStr)})
    //Remove duplicate listings
    result.listings = _.uniqWith(result.listings, (x, y) =>
      (x.addr[0].toUpperCase() == y.addr[0].toUpperCase()))

    // Place in Active and Sold buckets
    let active = [];
    let sold = [];

    result.listings.forEach((listing) => {
      let ignore = false;
      blacklist.forEach((bad) => {
        if ( listing.addr[0].toUpperCase().includes(bad.toUpperCase()) )
          ignore = true;
      });
      if (listing.status == 'Active' && !ignore )
        active.push(listing);
      else if (!ignore)
        sold.push(listing);
    })
    active = this.sortByPrice(active);
    sold = this.sortByPrice(sold);

    this.setState({
      loading: false,
      listings: _.concat(active,sold).map((listing) => {
        console.log(listing.addressStr)
        return {
          additionalString: listing.additionalString,
          learnMore: listing.learnMore,
          status: listing.status,
          feature: listing.feature,
          address: listing.addr,
          bath: listing.bathFull,
          bed: listing.bedrooms,
          price: listing.priceToShowStr,
          imgUrl: listing.middleThumbnailUrl,
          tourUrl: listing.virtualTourLink,
          detailsUrl: listing.virtualTourLink
        }
      }).filter(x => x.feature).slice(0,3)
    })
  }

  sortByPrice(listings) {
    return listings.sort((x, y) => y.priceToShow - x.priceToShow)
  }

  getAddress(addr){
    const titleCase = (str) => {
       var splitStr = str.toLowerCase().split(' ');
       for (var i = 0; i < splitStr.length; i++) {
           // You do not need to check if i is larger than splitStr length, as your for does that for you
           // Assign it back to the array
           splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
       }
       // Directly return the joined string
       return splitStr.join(' ');
    }

    addr = addr.replace(/<span>/g,"").split("</span>").map(i => {return i[0] == " "? i.replace(" ", ""): i}).slice(0,3)

    //Format address
    addr[0] = titleCase(addr[0])
    //consistent output
    addr[0] = addr[0].replace("Rd", "Road")
    if (addr[0].substring(addr[0].length-2, addr[0].length) == ' D')
      addr[0] = addr[0].replace(' D', ' Drive');
    if (addr[0].substring(addr[0].length-2, addr[0].length) == 'Dr')
      addr[0] = addr[0].replace(' Dr', ' Drive');



    const meta = addr[2].split(" ");
    addr[2] = meta[0]; addr.push(meta[1]);
    return addr;
  }

  render(){
    return (
      <div>
        <FeaturedListings
          listings= {this.state.listings}
        />
      </div>
      )
  }
}

class FeaturedListings extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
      const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    };
    const printColumn = (listing) =>{
      let sold = (<span style={{
                  float:'right',
                  marginRight:'0px',
                  fontSize: this.state.width < 910? '13px': '16px',
                  letterSpacing: '1px',
                  color: 'green'
                }}><strong>SOLD</strong></span>)
      if (listing.status == 'Active')
        sold = (<span></span>)

      let learnMore = (<div className="thumbnailCover">
                  <a href={
                    listing.tourUrl ? listing.detailsUrl : listing.detailsUrl
                  }><span className="thumbnailCoverText"
                  style= {{
                    top: this.state.width < 1000 ? '-30px' : 'auto',
                    fontSize: this.state.width > 801 && this.state.width < 1100 ? '24px' : '34px'
                  }}
                  >Learn more</span></a>
                  <a href={
                    listing.tourUrl ? listing.detailsUrl : listing.detailsUrl
                  } className="thumbnailCoverLink"></a>
                </div> )
      if (!listing.learnMore)
        learnMore = <div></div>
      return (
          <Grid.Column>
              <Card style={{
                width: this.state.width < 801 ? '300px' : 'auto'
              }} centered>
              <Image
              src={listing.imgUrl} wrapped ui={false} />
              {learnMore}
              <Card.Content>
                <Card.Header>{listing.address[0]} {sold}</Card.Header>
                <Card.Meta>
                  {listing.address[1]}, {listing.address[2]}
                  <span style={{textAlign:'right', fontSize: this.state.width < 910? '13px': '16px'}}>{listing.price}</span>
                </Card.Meta>
                <Card.Description>
                  <Icon size='small' name='bed' /> {listing.bed}
                  <Icon size='small' name='bath' style={{marginLeft: '10px'}} /> {listing.bath}
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        )
    }

    if (this.state.width < 801) {
      return (

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '200px'
        }}>
          <div style={{width: '90%'}}>
            <h2 className="sectionHead">Featured Listings</h2>

            <Slider {...settings}>
              {
                this.props.listings.map((listing) => (
                  <div>
                  <Grid centered style={{marginTop:'100px'}}>
                    <Grid.Row centered columns={1}>
                      {printColumn(listing)}
                    </Grid.Row>
                  </Grid>
                  </div>
                ))
              }
            </Slider>
          </div>
        </div>

      )
    } else {
      return (
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '300px'
        }}>
          <div style={{width: '70%'}}>

          <h2 className="sectionHead">Featured Listings</h2>

          <Grid centered style={{marginTop:'100px'}}>
            <Grid.Row centered columns={3}>
              {
                this.props.listings.map((listing) => printColumn(listing))
              }
            </Grid.Row>

          </Grid>
          </div>
        </div>
      )
    }
  }
}


class MainMenu extends Component {

  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }


  handleItemClick = (e, { name }) => {
    this.props.setPage(name);
  }

  render() {
    const { activePage } = this.props
    let width = window.innerWidth;
    console.log(width)
    return (
      <div>
        <Menu pointing secondary>
          <Menu.Menu position='left'>
            <Menu.Item as='a' header>
              <Image size='tiny' src='./logo.png' style={{ marginLeft: '2.5em', width: '100px' }} />
            </Menu.Item>
          </Menu.Menu>
          {(() => {
            if (this.state.width < 801)
              return (
                <Menu.Menu position='right'>
                <Dropdown className="mobileMenu" style={{
                  border: '0px',
                  zIndex:999
                }}trigger={(
                  <span>
                    <Image style={{ width: '45px', top: '20px' }}small src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/hamburger.png" />
                  </span>
                )} options={[
                  { key: 'home', text: 'Home', value: "home"},
                  { key: 'Listings', text: 'Listings', value:"Listings"},
                  { key: 'Services', text: 'Services', value: "Services"},
                  { key: 'Team', text: 'Meet the Team', value: "Meet the Team"},
                  { key: 'contact', text: 'Contact', value: "Contact"}
                ]} defaultValue={activePage} pointing='top right' icon={null} onChange={(e, data) => {
                  console.log(e,data)
                  console.log(data.value)
                  this.props.setPage(data.value)
                }} />

            </Menu.Menu>);
            else
              return (
                <Menu.Menu className="desktopMenu" position='right' style={{marginRight: '50px'}}>
                  <Menu.Item name='home' active={activePage === 'home'} onClick={this.handleItemClick} />
                  <Menu.Item
                    name='Listings'
                    active={activePage === 'Listings'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name='Services'
                    active={activePage === 'Services'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name='Meet the Team'
                    active={activePage === 'Meet the Team'}
                    onClick={this.handleItemClick}
                  />
                  <Menu.Item
                    name='Contact'
                    active={activePage === 'Contact'}
                    onClick={this.handleItemClick}
                  />
                </Menu.Menu>
              );
          })()}
        </Menu>

      </div>
    )
  }
}

class AboutUs extends Component{

  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
    let columns = 2;
    if (this.state.width < 801)
      columns = 1;
    return(

      <div>
      <div style={{
            position:'relative',
            marginTop:'150px'
          }}>
          <div style={{width:'100%',
                  position:'absolute',
                  backgroundColor:'white',
                  top:'0px',
                  height:'110%'
                }}>
            {( () => {
              if (this.state.width < 801)
                return (
                  <Hero
                    imageSrc='https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/about_us_mobile.jpg'
                    opacity={0.9}
                    minHeight="700px"
                    isFixed={false}
                    parallaxOffset={0}
                    isCentered={false}
                  >
                  </Hero>
                  );
              else
                return (
                  <Hero
                    imageSrc="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/aboutus1.jpg"
                    opacity={0.8}
                    minHeight="650px"
                    isFixed={false}
                    parallaxOffset={0}
                    isCentered={false}
                  >
                  </Hero>
                  );
                })()}

          </div>
        </div>

      <Grid>
          <Grid.Row columns={columns} >
            <Grid.Column width={columns == 1 ? 16 : 8}>
              <div style={{  }}>
                <div style={{fontFamily:'Raleway',
                  fontSize: '16px',
                  minWidth:'350px',
                  width: '50%',
                  fontWeight: '500',
                  letterSpacing: '0.3px',
                  marginLeft: columns == 1 ? '5%' : '20%'
                }}>
                  <hr style={{
                      marginTop: columns == 1 ? '0px' : '100px',
                      marginBottom: '-50px',
                      marginLeft: '0px',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0,
                      textAlign: 'left'
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    textAlign: 'left'
                  }}>AG Realty</h2>
                  <h2 className="sectionHead" style={{
                    marginBottom:'50px',
                    fontWeight: '700',
                    textAlign: 'left'
                  }}>The Gailas Team</h2>
                  <p style={{fontSize: columns == 1 ? '13px' : '16px', textAlign: 'left'}}>
                    AG Realty, an independent, boutique real estate firm located at the crossroads of Orinda was established in 2001 by Alexander Gailas. For the past decade AG Realty has enjoyed a high visibility profile as a solid, local business in the Lamorinda area, providing personalized service to over 300 clients. We specialize in the Orinda, Lafayette, Moraga, Walnut Creek and Alamo markets, with an in-depth knowledge of Contra Costa, Alameda and San Francisco counties.
                  </p>
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width={columns == 1 ? 16 : 8}>
              <div style={{
                fontFamily:'Raleway',
                width: columns == 1 ? '50%' : '80%',
                fontSize:'16px',
                textAlign: 'left',
                marginTop: columns == 1 ? '10px' : '160px',
                fontWeight: '500',
                letterSpacing: '0.3px',
                marginLeft: columns == 1 ? '5%' : '10%',
                minWidth: '350px'
              }}>
              <p style={{fontSize: columns == 1 ? '13px' : '16px', }}>
                Katia joined AG Realty in 2016 and has been working closely with her father, forming “The Gailas Team.”  Katia’s unbeatable energy matched with Alex’s professional expertise has allowed them to establish an outstanding reputation in the real estate industry.
              </p>
              <p>
                <strong style={{fontSize:'22px'}}>What sets the Gailas team apart?</strong>
              </p>
              <p style={{fontSize: columns == 1 ? '13px' : '16px', }}>
                Proven statistics.
              </p>
              <p style={{fontSize: columns == 1 ? '13px' : '16px', }}>
               The Gailas team has a 100% closing rate for their listings; they generally sell from 2 to 8% over the asking price. Together they have sold over 250 million dollars in residential and commercial real estate. Sixty percent of their Business is referral-based from previous clients and friends.
              </p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </div>
      )
  }
}


class BusinessCards extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
    return(
    <div className="businesscards" style={{
      marginTop:'200px',
      position: 'relative',
      padding: '35px'
    }}>

      <Grid>
        <Grid.Row columns={this.state.width < 600? 2 : 4} >
          <Grid.Column width={this.state.width < 600? 6 : 2}>
            <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/alex_bc.jpg" />
          </Grid.Column>
          <Grid.Column width={this.state.width < 600? 10 : 6}>
            <div style={{
              textAlign: 'left',
              width:'80%',
            }}>
              <h2>Alexander Gailas</h2>
              <p>
              Cell: (925) 788-0229 <br/>
              Office: (925) 254-7600 <br/>
              Fax: (925) 254-9251 <br/>
              Alex@AGrealty1.com<br/>
              </p>
              <a href="https://www.facebook.com/AGrealty1"><Icon size='big' name='facebook f' /></a>
              <a href="https://twitter.com/agrealtyteam?lang=en"><Icon size='big' name='twitter' /></a>
              <a href="https://www.linkedin.com/in/katia-gailas-379247108/"><Icon size='big' name='linkedin' /></a>
              <a href="https://www.yelp.com/biz/ag-realty-orinda-4?osq=agrealty"><Icon size='big' name='yelp' /></a>
            </div>
          </Grid.Column>
          <Grid.Column width={this.state.width < 600? 6 : 2} style={{marginTop: this.state.width < 600 ? '50px' : '0px'}}>
            <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/katia_bc.jpg" />
          </Grid.Column>
          <Grid.Column width={this.state.width < 600? 10 : 6}>
            <div style={{
              textAlign: 'left',
              width:'80%',
              marginTop: this.state.width < 600 ? '50px' : '0px'
            }}>
              <h2>Katerina Gailas</h2>
              <p>
              Cell: (925) 788-6646 <br/>
              Office: (925) 254-7600 <br/>
              Fax: (925) 254-9251 <br/>
              katia.gailas@AGrealty1.com<br/>
              </p>
              <a href="https://www.facebook.com/AGrealty1"><Icon size='big' name='facebook f' /></a>
              <a href="https://www.instagram.com/katiagailas/"><Icon size='big' name='instagram' /></a>
              <a href="https://www.linkedin.com/in/katia-gailas-379247108/"><Icon size='big' name='linkedin' /></a>
              <a href="https://www.yelp.com/biz/ag-realty-orinda-4?osq=agrealty"><Icon size='big' name='yelp' /></a>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
    )
  }
}

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {

    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
    return(
      <div>{
        (() => {
          if (this.state.width < 650)
            return (
              <Hero
                imageSrc="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/landing_mobile.jpg"
                opacity={0.0}
                minHeight="100vh"
                isFixed={false}
                parallaxOffset={0}
                isCentered={true}
              >
                <div style={{position:'relative', top:'-150px'}}>
                  <Button basic
                    style ={{
                      zIndex: 10
                    }}
                    size='massive'
                    onClick={() => this.props.meetTheTeam()}
                  >Meet the Gailas Team</Button>
                  <div className="buttonBackground"></div>
                </div>
              </Hero>);
          else
            return (
              <Hero
                imageSrc="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/landing.jpg"
                opacity={0.0}
                minHeight="100vh"
                isFixed={false}
                parallaxOffset={0}
                isCentered={false}
              >
              <Button basic
                className="mainButton"
                size='massive'
                onClick={() => this.props.meetTheTeam()}
                >Meet the Gailas Team</Button>
                <div className="buttonBackground"></div>
              </Hero>);
        })()
      }
        <AboutUs />
        <Featured />
        <SimpleSlider />
        <BusinessCards />
      </div>
      )
  }
}

class App extends Component {
  state = { activePage: 'home' }

  setPage = (page) => { this.setState({activePage: page}) }

  render(){
    const currentPage = () => {
      if (this.state.activePage == 'home'){
        return <LandingPage
          meetTheTeam={() => this.setPage('Meet the Team')}
        />
      } else if (this.state.activePage == 'Meet the Team') {
        return <MeetTheTeam />
      } else if (this.state.activePage == 'Listings') {
        return <Listings />
      } else if (this.state.activePage == 'Services') {
        return <Services />
      } else if (this.state.activePage == 'Contact') {
        return <Contact />
      }
    }

    return (
      <div className="App">
        <MainMenu
          setPage={this.setPage}
          activePage={this.state.activePage}
        />
        {currentPage()}
      </div>
    );
  }
}


export default App;
