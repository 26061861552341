import React, { Component } from 'react'
import { Menu, Grid, Card, Segment, Container, Header, Image, Button } from 'semantic-ui-react'
import './App.css';

class MeetTheTeam extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){

    if (this.state.width < 801)
      return (
        <div style={{marginTop: '100px'}}>
      <Grid>
        <Grid.Row columns={1}>
          <Grid.Column >
            <img src='https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/meetalex.jpg' style={{width:'100%', verticalAlign:'top'}}/>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
        <Grid.Column >
              <div>
                <div style={{position:'relative', top:'50px'}}>
                  <hr style={{
                      marginBottom: '-50px',
                      marginLeft: '5%',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    marginLeft: '5%',
                    textAlign: 'left',
                    fontSize:'26px'
                  }}>Alexander Gailas</h2>
                  <h2 className="sectionHead" style={{
                    marginBottom:'50px',
                    fontWeight: '700',
                    textAlign: 'left',
                    fontSize:'26px',
                    marginLeft:'5%'
                  }}>President, AG Realty</h2>
                  <p style={{
                    width:'90%', textAlign: 'left', marginLeft:'5%'
                  }}>
                    Alex is a Graduate from San Francisco State University, he holds a California Broker license, a Graduate from Realtor Institute (GRI) and is a Certified Residential Specialist (CRS), a credential achieved by only 4% of all Realtors Nationally. Alex has entered into Real Estate industry in 1999. In 2001 Alex started his own Real Estate Brokerage " AG Realty". AG Realty has become one of the reputable boutique independent companies specializing in Residential sales in the Lamorinda area.
                  </p>
                </div>
              </div>
            </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column >
          <img src='https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/kg3.jpg' style={{width:'100%', verticalAlign:'top', marginTop:'100px'}}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column >
              <div>
                <div style={{position:'relative', top:'50px'}}>
                  <hr style={{
                      marginBottom: '-50px',
                      marginLeft: '5%',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    marginLeft: '5%',
                    textAlign: 'left',
                    fontSize:'26px'
                  }}>Katia Gailas</h2>
                  <h2 className="sectionHead" style={{
                    marginBottom:'50px',
                    fontWeight: '700',
                    textAlign: 'left',
                    marginLeft: '5%',
                    fontSize:'26px'
                  }}>Real Estate Consultant</h2>
                  <p style={{
                    width:'90%', textAlign: 'left', marginLeft:'5%'
                  }}>
                    Katia completed her Bachelor of Fine Arts at the School of the Art Institute of Chicago, while also studying art extensively in Italy. Art has always been a passion of hers and she has found her creativity best utilized in the business arena. Her detail-oriented mindset, aided by her educational background in the arts, have allowed her to excel in competitive markets whether it is negotiation, marketing to produce ultimate exposure, or a strong comprehension of market trends to provide clients with the greatest education and advantage.
                  </p>
                </div>
              </div>
            </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div style={{
          width:'100%',
          justifyContent:'center',
          display:'flex'
        }}>
          <h2 className="sectionHead" style={{
            marginTop: '120px',
            fontWeight:'700',
            textAlign: 'center',
            fontSize: '18px'
          }}>Meet Our Teammates</h2>
        </div>
      </Grid.Row>
       <Grid.Row columns={2} style={{marginTop:'50px'}}>
          <Grid.Column>
            <Grid.Row>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/lucia.jpg" style={{
                width:'150px',
                borderRadius:'250px'
              }}/>
            </Grid.Row>
            <Grid.Row>
              <div style={{justifyContent:'center', display:'flex', marginTop:'20px'}}>
                <div>
                <h2 className="sectionHead" style={{
                  fontWeight:'100',
                  marginBottom: '-25px',
                  fontSize:'18px',
                  textAlign: 'center'
                }}>Lucia White</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'center',
                  fontSize:'18px',
                }}>Real Estate Consultant</h2>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/michael.jpg" style={{
                width:'150px',
                borderRadius:'250px'
              }}/>
            </Grid.Row>
            <Grid.Row>
              <div style={{justifyContent:'center', display:'flex', marginTop:'20px'}}>
                <div>
                <h2 className="sectionHead" style={{
                  fontWeight:'100',
                  marginBottom: '-25px',
                  fontSize:'18px',
                  textAlign: 'center'
                }}>Michael Gailas</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'center',
                  fontSize:'18px',
                }}>Marketing Specialist</h2>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
      </Grid.Row>
      </Grid>
      </div>
        );
    else
    return (
      <div style={{marginTop: '100px'}}>
      <Grid>
      <Grid.Row columns={2} style={{
        }}>
        <Grid.Column width={11} style={{
          padding: `0px`
        }}>
          <img src='https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/meetalex.jpg' style={{width:'100%', verticalAlign:'top'}}/>
        </Grid.Column>
        <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription" style={{left:'-150px'}}>
                <div style={{position:'relative', top:'50px'}}>
                  <hr style={{
                      marginTop: '100px',
                      marginBottom: '-50px',
                      marginLeft: '50px',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    marginLeft: '50px',
                    textAlign: 'left'
                  }}>Alexander Gailas</h2>
                  <h2 className="sectionHead" style={{
                    marginBottom:'50px',
                    fontWeight: '700',
                    textAlign: 'right'
                  }}>President, AG Realty</h2>
                  <p style={{
                    width:'90%', textAlign: 'left', marginLeft:'50px'}}>
                    Alex is a Graduate from San Francisco State University, he holds a California Broker license, a Graduate from Realtor Institute (GRI) and is a Certified Residential Specialist (CRS), a credential achieved by only 4% of all Realtors Nationally. Alex has entered into Real Estate industry in 1999. In 2001 Alex started his own Real Estate Brokerage " AG Realty". AG Realty has become one of the reputable boutique independent companies specializing in Residential sales in the Lamorinda area.
                  </p>
                </div>
              </div>
            </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={5} style={{
              padding: `0px`
            }}>
          <div className="serviceDescription" id="meetKatia">
            <div style={{position:'relative', top:'50px', marginLeft: '0px'}}>
              <hr style={{
                  marginTop: '100px',
                  marginBottom: '-50px',
                  marginLeft: '0px',
                  width: '100px',
                  height: '8px',
                  backgroundColor: '#5c4817',
                  border: 0
              }}/>
              <h2 className="sectionHead" style={{
                marginTop: '100px',
                fontWeight:'100',
                marginBottom: '-25px',
                marginLeft: '0px',
                textAlign: 'left'
              }}>Katia Gailas</h2>
              <h2 className="sectionHead" style={{
                marginBottom:'50px',
                fontWeight: '700',
                textAlign: 'left',
                marginLeft: '20px'
              }}>Real Estate Consultant</h2>
              <p style={{
                width:'90%', textAlign: 'left', marginLeft:'0px'}}>
                Katia completed her Bachelor of Fine Arts at the School of the Art Institute of Chicago, while also studying art extensively in Italy. Art has always been a passion of hers and she has found her creativity best utilized in the business arena. Her detail-oriented mindset, aided by her educational background in the arts, have allowed her to excel in competitive markets whether it is negotiation, marketing to produce ultimate exposure, or a strong comprehension of market trends to provide clients with the greatest education and advantage.
              </p>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={11} style={{
          padding: `0px`,
        }}>
          <img src='https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/kg3.jpg' style={{width:'100%', verticalAlign:'top'}}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <div style={{
          width:'100%',
          justifyContent:'center',
          display:'flex'
        }}>
          <h2 className="sectionHead" style={{
            marginTop: '120px',
            fontWeight:'700',
            textAlign: 'center'
          }}>Meet Our Teammates</h2>
        </div>

      </Grid.Row>
      <Grid.Row columns={3} style={{marginTop:'50px'}}>
          <Grid.Column>
            <Grid.Row>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/lucia.jpg" style={{
                width:'250px',
                borderRadius:'250px'
              }}/>
            </Grid.Row>
            <Grid.Row>
              <div style={{justifyContent:'center', display:'flex', marginTop:'20px'}}>
                <div>
                <h2 className="sectionHead" style={{
                  fontWeight:'100',
                  marginBottom: '-25px',
                  fontSize:'22px',
                  textAlign: 'left'
                }}>Lucia White</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left',
                  fontSize:'22px',
                  marginLeft: '20px'
                }}>Real Estate Consultant</h2>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/Aladdin.jpg" style={{
                width:'250px',
                borderRadius:'250px'
              }}/>
            </Grid.Row>
            <Grid.Row>
              <div style={{justifyContent:'center', display:'flex', marginTop:'20px'}}>
                <div>
                <h2 className="sectionHead" style={{
                  fontWeight:'100',
                  marginBottom: '-25px',
                  fontSize:'22px',
                  textAlign: 'left'
                }}>Aladdin Kanawati</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left',
                  fontSize:'22px',
                  marginLeft: '20px'
                }}>Real Estate Consultant</h2>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/michael.jpg" style={{
                width:'250px',
                borderRadius:'250px'
              }}/>
            </Grid.Row>
            <Grid.Row>
              <div style={{justifyContent:'center', display:'flex', marginTop:'20px'}}>
                <div>
                <h2 className="sectionHead" style={{
                  fontWeight:'100',
                  marginBottom: '-25px',
                  fontSize:'22px',
                  textAlign: 'left'
                }}>Michael Gailas</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left',
                  fontSize:'22px',
                  marginLeft: '20px'
                }}>Marketing Specialist</h2>
                </div>
              </div>
            </Grid.Row>
          </Grid.Column>
      </Grid.Row>
      </Grid>
      </div>
    );
  }
}


export default MeetTheTeam