import React, { Component } from 'react';
import request from 'request-promise';
import _ from 'lodash';
import Slider from "react-slick";
import { Menu, Grid, Card, Segment, Container, Header, Image, Button, Icon } from 'semantic-ui-react'
import searchByActive from './searchByActive.js';
import searchByPrice from './searchByPrice.js';


//Then set a limit of 9 per page
//Fix 3 columns issue
const blacklist = [
  "13 Virginia",
  "29 Canyon"
];

class Featured extends Component {
  state = { loading: true, listings:[], active:[], sold: [] }

  componentDidMount(){
    let result = searchByPrice;
    if (this.props.SortBy == 'internal_status')
      result = searchByActive;
    //Exctract address
    result.listings.map(listing => {listing.addr = this.getAddress(listing.addressStr)})
    //Remove duplicate listings
    result.listings = _.uniqWith(result.listings, (x, y) =>
      (x.addr[0].toUpperCase() == y.addr[0].toUpperCase()))

    // Place in Active and Sold buckets
    let active = [];
    let sold = [];

    result.listings.forEach((listing) => {
      let ignore = false;
      blacklist.forEach((bad) => {
        if (listing.addr[0].toUpperCase().includes(bad.toUpperCase()))
          ignore = true;
      });
      if (listing.status == 'Active' && !ignore)
        active.push(listing);
      else if (!ignore)
        sold.push(listing);
    })
    active = this.sortByPrice(active);
    //temp
    sold = this.sortByPrice(sold);
    this.setState({
      loading: false,
      active: active.map((listing) => {
        console.log(listing.addressStr)
        return {
          additionalString: listing.additionalString,
          learnMore: listing.learnMore,
          address: listing.addr,
          bath: listing.bathFull,
          bed: listing.bedrooms,
          price: listing.priceToShowStr,
          imgUrl: listing.middleThumbnailUrl,
          tourUrl: listing.virtualTourLink,
          detailsUrl: listing.virtualTourLink
        }
      }),
      sold: sold.map((listing) => {
        console.log(listing.addressStr)
        return {
          learnMore: listing.learnMore,
          address: listing.addr,
          bath: listing.bathFull,
          bed: listing.bedrooms,
          price: listing.priceToShowStr,
          imgUrl: listing.middleThumbnailUrl,
          tourUrl: listing.virtualTourLink,
          detailsUrl: listing.virtualTourLink
        }
      }).slice(0,66),
      listings: _.concat(active,sold).map((listing) => {
        console.log(listing.addressStr)
        return {
          learnMore: listing.learnMore,
          address: listing.addr,
          bath: listing.bathFull,
          bed: listing.bedrooms,
          price: listing.priceToShowStr,
          imgUrl: listing.middleThumbnailUrl,
          tourUrl: listing.virtualTourLink,
          detailsUrl: listing.virtualTourLink
        }
      }).slice(0,3)
    })
  }

  sortByPrice(listings) {
    return listings.sort((x, y) => y.priceToShow - x.priceToShow)
  }

  getAddress(addr){
    const titleCase = (str) => {
       var splitStr = str.toLowerCase().split(' ');
       for (var i = 0; i < splitStr.length; i++) {
           // You do not need to check if i is larger than splitStr length, as your for does that for you
           // Assign it back to the array
           splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
       }
       // Directly return the joined string
       return splitStr.join(' ');
    }

    addr = addr.replace(/<span>/g,"").split("</span>").map(i => {return i[0] == " "? i.replace(" ", ""): i}).slice(0,3)

    //Format address
    addr[0] = titleCase(addr[0])
    //consistent output
    addr[0] = addr[0].replace("Rd", "Road")
    if (addr[0].substring(addr[0].length-2, addr[0].length) == ' D')
      addr[0] = addr[0].replace(' D', ' Drive');
    if (addr[0].substring(addr[0].length-2, addr[0].length) == 'Dr')
      addr[0] = addr[0].replace(' Dr', ' Drive');

    if (addr[2]) {
      const meta = addr[2].split(" ");
      addr[2] = meta[0]; addr.push(meta[1]);
    }
    return addr;
  }

  render(){
    const listingTypes = {
      featured: <FeaturedListings
        listings= {this.state.listings}
      />,
      active: <FeaturedListings
        listings= {this.state.active}
      />,
      sold: <FeaturedListings
        listings= {this.state.sold}
      />
    }
    return (
      <div>
        {listingTypes[this.props.listingType]}
      </div>
      )
  }
}

class FeaturedListings extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
    const printColumn = (listing) =>{
      let learnMore = (<div className="thumbnailCover">
                  <a href={
                    listing.tourUrl ? listing.detailsUrl : listing.detailsUrl
                  }><span className="thumbnailCoverText"
                  style= {{
                    top: this.state.width < 1000 ? '-30px' : 'auto',
                    fontSize: this.state.width > 801 && this.state.width < 1100 ? '24px' : '34px'
                  }}
                  >Learn more</span></a>
                  <a href={
                    listing.tourUrl ? listing.detailsUrl : listing.detailsUrl
                  } className="thumbnailCoverLink"></a>
                </div> )
      if (!listing.learnMore)
        learnMore = <div></div>
      return (
          <Grid.Column style={{marginTop:'30px'}}>
              <Card style={{ width: this.state.width < 801 ? '300px' : 'auto' }} centered>
              <Image src={listing.imgUrl} wrapped ui={false} />
                {learnMore}
              <Card.Content>
                <Card.Header>{listing.address[0]}</Card.Header>
                <Card.Meta>
                  {listing.address[1]}, {listing.address[2]}
                  <span style={{textAlign:'right'}}>{listing.price}</span>
                </Card.Meta>
                <Card.Description>
                  <Icon size='small' name='bed' /> {listing.bed}
                  <Icon size='small' name='bath' style={{marginLeft: '10px'}} /> {listing.bath}
                  <span style={{float:'right'}}>
                  <strong>{listing.additionalString}</strong>
                  </span>
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        )
    }

    let listingIterator = [];
    for(var i = 0; i < this.props.listings.length; i += 6) {
      listingIterator.push(this.props.listings.slice(i, i+6));
    }
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 10000
    };
    console.log(listingIterator)
    const lastGroup = _.get(listingIterator,`[${listingIterator.length-1}].length`) || 0;
    const addColumns = () => {
      if(lastGroup == 1) return <div><Grid.Column></Grid.Column><Grid.Column></Grid.Column></div>;
      else if (lastGroup == 2) return <Grid.Column></Grid.Column>;
    }
    console.log(lastGroup)
    console.log(addColumns())

    return (
      <div>
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
        }}>
          <div style={{ width:'70%'}}>



            {listingIterator.map((listingGroup) =>
              <div>
              <Grid centered style={{ }}>
              <Grid.Row centered columns={this.state.width < 600 ? 1 : 3 }>
                {
                  listingGroup.map((listing) => printColumn(listing))
                }
                { addColumns() }
                <Grid.Column></Grid.Column>
                <Grid.Column></Grid.Column>
              </Grid.Row>
              </Grid>
              </div>
            )}

          </div>

        </div>

          </div>
  )
  }
}

class Listings extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render() {
    return(
    <div style={{marginTop: '100px'}}>
      <Grid>
          <Grid.Row columns={1} style={{
              padding: `0px`
            }}>
            <Grid.Column width={16} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription" style={{
                height: '250px',
                width: this.state.width < 601 ? '90%' : '1100px',
                marginLeft: this.state.width < 601 ? '10%' : '0px',
                left: this.state.width < 601 ? '0px' : '100px' ,
                top: '0px'
              }}>

                <div style={{position:'relative'}}>
                  <hr style={{
                      marginBottom: '-50px',
                      marginLeft: '0px',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0,
                      textAlign: 'left'
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    textAlign: 'left'
                  }}>Active Listings</h2>
                  <h2 className="sectionHead" style={{
                    fontWeight: '700',
                    textAlign: 'left'
                  }}>Orinda, Moraga, Lafayette & the Greater Bay Area</h2>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Featured
              listingType="active"
              SortBy="internal_status"
              Asc="asc"
            />
          </Grid.Row>
          <Grid.Row columns={1} style={{
              padding: `0px`
            }}>
            <Grid.Column width={16} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription" style={{
                height: '250px',
                width: this.state.width < 601 ? '95%' : '1100px',
                marginLeft: this.state.width < 601 ? '10%' : '0px',
                left: this.state.width < 601 ? '0px' : '100px',
                marginTop: '150px',
                fontSize: this.state.width < 601 ? '26px' : '32px',
                top: '0px'
              }}>
                <div style={{position:'relative'}}>
                  <hr style={{
                      marginBottom: '-50px',
                      marginLeft: '0px',
                      width: '100px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0,
                      textAlign: 'left'
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    textAlign: 'left'
                  }}>Past Listings</h2>
                  <h2 className="sectionHead" style={{
                    fontWeight: '700',
                    textAlign: 'left'
                  }}>Over 25 years of Real Estate Business in the Bay Area</h2>
                </div>
              </div>
            </Grid.Column>
            <Grid.Row>
              <Featured
                listingType="sold"
                SortBy="price"
                Asc="desc"
                />
            </Grid.Row>
          </Grid.Row>

        </Grid>
      </div>
      )
  }
}

export default Listings;