module.exports = {
    "paging": {
        "pageSize": 30,
        "currentPageNum": 0,
        "total": 152
    },
    "listings": [
        {
            "learnMore": false,
            "feature": true,
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>4680 Green Valley Lane</span> <span>Fairfield</span> <span>CA 00000</span>",
            "detailsURL": "https://www.4680greenvalley.com",
            "detailsClientViewURL": "https://www.4680greenvalley.com",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/THUMBNAIL.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/4680_green_valley.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/THUMBNAIL.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 1599000,
            "priceToShowStr": "$1,599,000",
            "squareFeet": null,
            "lotSize": ".94",
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": null,
            "bathTotalAsStr": "4",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "https://www.4680greenvalley.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "addressStr": "<span>209 Blue Ridge Dr</span> <span>Martinez</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/209_Blue_Ridge.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1qMWKKV66bX99SYY7P8ROiCU1eoRlMYMn",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/209_Blue_Ridge.jpg",
            "status": "Sold",
            "priceToShow": 465000,
            "priceToShowStr": "$465,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>35 Village Square Pl</span> <span>Pleasant Hill</span> <span>CA</span>",
            "pictureURL": "https://drive.google.com/uc?export=view&id=1OCwvX65jbuEHeIxANySVZHWXOe2hVKdq",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1OCwvX65jbuEHeIxANySVZHWXOe2hVKdq",
            "smallThumbnailUrl": "https://drive.google.com/uc?export=view&id=1OCwvX65jbuEHeIxANySVZHWXOe2hVKdq",
            "status": "Sold",
            "priceToShow": 575000,
            "priceToShowStr": "$575,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>39 Village Square Pl</span> <span>Pleasant Hill</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/39_Village_Square.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1pMOPqP74yJ1eO-sq9AmXjTC-JH3eA0iK",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/39_Village_Square.jpg",
            "status": "Sold",
            "priceToShow": 583000,
            "priceToShowStr": "$583,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>1061 Buchan Dr</span> <span>Lafayette</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1061_Buchan_Dr.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1ZkIMikD93Ac7cNd4AihbF3NqPbr5YLiP",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1061_Buchan_Dr.jpg",
            "status": "Sold",
            "priceToShow": 605000,
            "priceToShowStr": "$605,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>87 Loma Vista Dr</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/87_Loma_Vista_Dr.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1Ux3PPua-Fy0vvpoc2v0TPn-JooBDUWDD",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/87_Loma_Vista_Dr.jpg",
            "status": "Sold",
            "priceToShow": 699000,
            "priceToShowStr": "$699,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>1210 Elmwood Dr</span> <span>Walnut Creek</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1210_Elmwood_Dr.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1nsQ7OuY5kYkwzFs5ZqxLDTGoMSRwi5Kg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1210_Elmwood_Dr.jpg",
            "status": "Sold",
            "priceToShow": 680000,
            "priceToShowStr": "$680,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>256 Rheem Blvd</span> <span>Moraga</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/256_Rheem_Blvd.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1iBuIPO82noVLTmj40uQvpgrdyrLoBQvp",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/256_Rheem_Blvd.jpg",
            "status": "Sold",
            "priceToShow": 660000,
            "priceToShowStr": "$660,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>1329 Corte De los Vecinos</span> <span>Walnut Creek</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1329_Corte_De_Los_Vecinos.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1tGJMx2xEYUkFm5edp6E1iRCpVbUswIFM",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/1329_Corte_De_Los_Vecinos.jpg",
            "status": "Sold",
            "priceToShow": 600000,
            "priceToShowStr": "$600,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>2335 Banbury Loop</span> <span>Martinez</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/2335_Banbury_loop.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1-6JjSm4zYqFmnVLy_ipvy06P50TtfiUC",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set_update/2335_Banbury_loop.jpg",
            "status": "Sold",
            "priceToShow": 720000,
            "priceToShowStr": "$720,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "5",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>63 Meadow View Road</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/63_Meadow_view_rd.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1arsUdjlTRFObwzsFeBK1D3xPQD9Lfwko",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/63_Meadow_view_rd.jpg",
            "status": "Sold",
            "priceToShow": 758000,
            "priceToShowStr": "$758,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>277 Glorietta Blvd</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/277_Glorietta.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1MdEaXKCtGv1wt7ATKMZl3gX4j8r0mb1w",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/277_Glorietta.jpg",
            "status": "Sold",
            "priceToShow": 767500,
            "priceToShowStr": "$767,500",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>23 Muth Dr</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://drive.google.com/uc?export=view&id=10SrjcbjKGRdpU4MXWsB1WjSQn-sTSG0G",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=10SrjcbjKGRdpU4MXWsB1WjSQn-sTSG0G",
            "smallThumbnailUrl": "https://drive.google.com/uc?export=view&id=10SrjcbjKGRdpU4MXWsB1WjSQn-sTSG0G",
            "status": "Sold",
            "priceToShow": 850000,
            "priceToShowStr": "$850,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>251 Overhill Rd</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/251_Overhill_Rd.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=12LboPKvbtnNITD7k9kOhHSQaTGH1jhOp",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/251_Overhill_Rd.jpg",
            "status": "Sold",
            "priceToShow": 800000,
            "priceToShowStr": "$800,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>15 W Austin Ct</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/15_W_Austin.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1ACFNCUZ9ZMTT_CIC--WRL8tEiC2tzy1A",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/15_W_Austin.jpg",
            "status": "Sold",
            "priceToShow": 850000,
            "priceToShowStr": "$850,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>3142 Sandalwood Ct</span> <span>Lafayette</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/3142_sandalwood.png",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1P9G6O7NFiDKyCHBqqErPPhrLCMdgpNL3",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/3142_sandalwood.png",
            "status": "Sold",
            "priceToShow": 880000,
            "priceToShowStr": "$880,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "5",
            "bathFull": "5",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>170 Paseo Del Rio</span> <span>Moraga</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/170_Paseo_Del_Rio.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1GS3XL6d_TLuohsPhHwFrKTBlzzZSckey",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/170_Paseo_Del_Rio.jpg",
            "status": "Sold",
            "priceToShow": 892000,
            "priceToShowStr": "$892,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "3",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>29 Muth Dr</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/29_Muth_drive.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1S1SqYcF3Ozm5DMIM7EZFKKDza7cQI3kD",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/29_Muth_drive.jpg",
            "status": "Sold",
            "priceToShow": 962000,
            "priceToShowStr": "$962,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>83 Coral Dr</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/83_coral.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=1UuFoA8ykyaGZd3PW9HAeuM1N_8ah_GF8",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/83_coral.jpg",
            "status": "Sold",
            "priceToShow": 975000,
            "priceToShowStr": "$975,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "6",
            "bathFull": "4",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>83 Coral Dr</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/83_coral.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/83_coral.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/83_coral.jpg",
            "status": "Sold",
            "priceToShow": 975000,
            "priceToShowStr": "$975,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "6",
            "bathFull": "4",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>162 Overhilll Rd</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/162_Overhill_Rd.jpg",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=181JuqO6NJkQsnIlxZ-GlRyS-Oqba5xbM",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/162_Overhill_Rd.jpg",
            "status": "Sold",
            "priceToShow": 1395000,
            "priceToShowStr": "$1,395,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "3",
            "bathFull": "2",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "addressStr": "<span>120 Sleep Hollow Lane</span> <span>Orinda</span> <span>CA</span>",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/120_Sleepy_Hollow.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/120_Sleepy_Hollow.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/soldhomes_411set/120_Sleepy_Hollow.jpg",
            "status": "Sold",
            "priceToShow": 1975000,
            "priceToShowStr": "$1,975,000",
            "squareFeet": "",
            "lotSize": "",
            "lotFeet": "",
            "lotAcres": undefined,
            "bedrooms": "4",
            "bathFull": "3.5",
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "county": "CONTRA COSTA",
            "latitude": undefined,
            "longitude": undefined,
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40683752",
            "lid": "40683752",
            "addressStr": "<span>13 VIRGINIA DR</span> <span>Orinda</span> <span>CA 94563-3514</span>",
            "detailsURL": "https://agrealty1.com/IDX/13-VIRGINIA-DR-Orinda-CA-94563-3514/40683752_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/13-VIRGINIA-DR-Orinda-CA-94563-3514/40683752_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 3375000,
            "priceToShowStr": "$3,375,000",
            "squareFeet": "7848",
            "lotSize": "69696",
            "lotFeet": 69696,
            "lotAcres": 1.6,
            "bedrooms": "5",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "5",
            "listDate": "01/10/15",
            "expiresDate": "",
            "saleDate": "02/26/15",
            "daysOnMarket": "47",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Dramatic SINGLE STORY Contemporary estate offers luxury life style, Recreation Room downstairs only, private lush grounds, large pool, outdoor kitchen, Guest cottage, Fitness center, Tennis court, Putting green and Playground. Ideal location, short distance to schools, freeway and downtown",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1986",
            "latitude": 37.8672945,
            "longitude": -122.1623202,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40683752/0/0/0/d1b87a1208d855fab95b366248c9f3ea/22/b5c5fe2c55b2a6170f0036b6dc2a2b2d/40683752.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "645044609204",
            "lid": null,
            "addressStr": "<span>29 Canyon View Drive</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/29-Canyon-View-Drive-Orinda-CA-94563/645044609204_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/29-Canyon-View-Drive-Orinda-CA-94563/645044609204_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "middleThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg",
            "status": "Active",
            "listingClass": "Acreage",
            "priceToShow": 2699000,
            "priceToShowStr": "$2,699,000",
            "squareFeet": null,
            "lotSize": ".94",
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "5",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": null,
            "bathTotalAsStr": "4",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "https://www.29canyonview.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40801144",
            "lid": "40801144",
            "addressStr": "<span>51 Martha Rd</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/51-Martha-Rd-Orinda-CA-94563/40801144_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/51-Martha-Rd-Orinda-CA-94563/40801144_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/51_Martha_Rd.jpg",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1850000,
            "priceToShowStr": "$1,850,000",
            "squareFeet": "2956",
            "lotSize": "35000",
            "lotFeet": 35000,
            "lotAcres": 0.8,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "4",
            "listDate": "10/19/17",
            "expiresDate": "",
            "saleDate": "11/17/17",
            "daysOnMarket": "29",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Amazing well-loved home, first time on the market by Original owners, very gracious living spaces, distinctive Tudor, fabulous great room, updated kitchen, conservatory sun room, Bath spa, hardwood floors, 2 fireplaces, beautiful private back yard, Perfect set up for in-law quarters, so many amenities ... too much to mention. 2 min. to Glorietta Elementary, 5 min. to Downtown, BART and HWY 24 One of the most desirable neighborhoods of Glorietta area. Top rated schools",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1977",
            "latitude": 37.8732842,
            "longitude": -122.1668202,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40801144/0/0/0/748cfdb11f2416948a62298ceba88cd3/52/9aa1b18f509d77c39b974289d66838a4/40801144.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "feature": true,
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641229",
            "lid": null,
            "addressStr": "<span>797 Moraga Rd</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/797-Moraga-Rd-Lafayette-CA-94549/12732641229_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/797-Moraga-Rd-Lafayette-CA-94549/12732641229_PM/0005601",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/moraga797.jpg",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 1849000,
            "priceToShowStr": "$1,849,000",
            "squareFeet": "3300",
            "lotSize": ".9 acre",
            "lotFeet": null,
            "lotAcres": 0.9,
            "bedrooms": "4",
            "bathFull": "4.5",
            "bathPart": null,
            "bathAsStr": "4.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4.5",
            "listDate": "04/06/18",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "429",
            "age": null,
            "statusPresentation": "Active",
            "virtualTourLink": "https://www.797moragaroad.com",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "2005",
            "latitude": 37.8809745,
            "longitude": -122.1220412,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1134505914718.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40816518",
            "lid": "40816518",
            "addressStr": "<span>797 MORAGA RD</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/797-MORAGA-RD-Lafayette-CA-94549/40816518_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/797-MORAGA-RD-Lafayette-CA-94549/40816518_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1845000,
            "priceToShowStr": "$1,845,000",
            "squareFeet": "3300",
            "lotSize": "38000",
            "lotFeet": 38000,
            "lotAcres": 0.87,
            "bedrooms": "4",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "5",
            "listDate": "04/06/18",
            "expiresDate": "",
            "saleDate": "08/16/18",
            "daysOnMarket": "132",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.797moragaroad.com",
            "description": "A Custom built stunning estate beautifully detailed by an artistic Master Builder, Combining Old World and Neo-Classic Designs, exudes Charm, Intimacy and Character. This Estate is unparalleled in detail with Moldings, Columns, Fresco ceilings, Lighting, Travertine/Marble/Limestone Floors and, Architecturally worthy Designs. This Estate is on .87 of an acre of Roman Landscaping with fountains, Flowers, Trailing Plants, cypress Trees, A large upper flat-lawn area under a canopy of trees with a lovely Pavilion. An Inviting Spa, Atrium and Sunroom, A soothing waterfall cascades into the sunlit sparkling pool with a Cabana and Seating Area. Enjoy unobstructed views of the Lafayette Hills, Privacy and Serenity for Complete Relaxation and Enjoyment. Min. to Downtown and BART. Top rate Lafayette schools",
            "county": "CONTRA COSTA",
            "geoArea": "5100",
            "distance": null,
            "yearBuilt": "2003",
            "latitude": 37.8809745,
            "longitude": -122.1220412,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40816518/0/0/0/87ead8c928d2cbdb4121ed9000280d21/68/6c80ab8dc6e4c714e83d63597569e128/40816518.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641419",
            "lid": null,
            "addressStr": "<span>39 Park Terrace</span> <span>Walnut Creek</span> <span>CA 94597</span>",
            "detailsURL": "https://agrealty1.com/IDX/39-Park-Terrace-Walnut-Creek-CA-94597/12732641419_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/39-Park-Terrace-Walnut-Creek-CA-94597/12732641419_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1198971739191.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/39_park_terrace_ct.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1198971739191.jpg",
            "status": "Other",
            "listingClass": "Single Family Res",
            "priceToShow": 1650000,
            "priceToShowStr": "$1,650,000",
            "squareFeet": "5000",
            "lotSize": null,
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "5",
            "bathFull": "4",
            "bathPart": null,
            "bathAsStr": "4",
            "bathPartAsStr": null,
            "bathTotalAsStr": "4",
            "listDate": "12/29/07",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "4180",
            "age": null,
            "statusPresentation": null,
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "2007",
            "latitude": 37.9086906,
            "longitude": -122.080999,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1198971739191.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641241",
            "lid": null,
            "addressStr": "<span>811 Crossbrook Drive</span> <span>Moraga</span> <span>CA 94556</span>",
            "detailsURL": "https://agrealty1.com/IDX/811-Crossbrook-Drive-Moraga-CA-94556/12732641241_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/811-Crossbrook-Drive-Moraga-CA-94556/12732641241_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1436984357820_0.JPG",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/811_crossbrook_dr.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1436984357820_0.JPG",
            "status": "Sold",
            "listingClass": "Acreage",
            "priceToShow": 1620000,
            "priceToShowStr": "$1,620,000",
            "squareFeet": null,
            "lotSize": ".46",
            "lotFeet": null,
            "lotAcres": 0.46,
            "bedrooms": "4",
            "bathFull": "3.5",
            "bathPart": null,
            "bathAsStr": "3.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3.5",
            "listDate": "06/30/15",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "1440",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1966",
            "latitude": 37.8430419,
            "longitude": -122.12972,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1436984357820_0.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40798330",
            "lid": "40798330",
            "addressStr": "<span>917 Lee Ln</span> <span>Concord</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/917-Lee-Ln-Concord-CA-94518/40798330_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/917-Lee-Ln-Concord-CA-94518/40798330_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1600000,
            "priceToShowStr": "$1,600,000",
            "squareFeet": "5040",
            "lotSize": "81022",
            "lotFeet": 81022,
            "lotAcres": 1.86,
            "bedrooms": "6",
            "bathFull": "5",
            "bathPart": null,
            "bathAsStr": "5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "6",
            "listDate": "09/25/17",
            "expiresDate": "",
            "saleDate": "08/17/18",
            "daysOnMarket": "326",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.917leelane.com",
            "description": "Wow! It is not very often that we find a property like this in the area.Experience Estate living at the end of quiet cul-de-sac bordering Walnut Creek and Pleasant Hill surrounded by other acreage estates in a prestigious neighborhood. Rebuilt 5040 squ. ft. from foundation up in 2006. Too much to list. MUST SEE! 3 minutes to all private schools and highly accredited Carondelet and De La salle high schools, BART, 24/680 freeways, shopping, restaurants, Iron Horse trail and night life. You will be amazed!",
            "county": "CONTRA COSTA",
            "geoArea": "5701",
            "distance": null,
            "yearBuilt": "2006",
            "latitude": 37.9394441,
            "longitude": -122.0401232,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40798330/0/0/0/e84b083e6247b59ca9e5e47ff74f6bb1/52/589f75eb010e7a4d0a40361a1c8d7e19/40798330.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641433",
            "lid": null,
            "addressStr": "<span>715 Bridle Ridge Drive</span> <span>Fairfield</span> <span>CA 94534</span>",
            "detailsURL": "https://agrealty1.com/IDX/715-Bridle-Ridge-Drive-Fairfield-CA-94534/12732641433_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/715-Bridle-Ridge-Drive-Fairfield-CA-94534/12732641433_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1499381015079_0.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/715_bridle_ridge_dr.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1499381015079_0.jpg",
            "status": "Sold",
            "listingClass": "Acreage",
            "priceToShow": 1450000,
            "priceToShowStr": "$1,450,000",
            "squareFeet": null,
            "lotSize": "59242",
            "lotFeet": 59242,
            "lotAcres": null,
            "bedrooms": "5",
            "bathFull": "5",
            "bathPart": null,
            "bathAsStr": "5",
            "bathPartAsStr": null,
            "bathTotalAsStr": "5",
            "listDate": "06/30/17",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "709",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "2016",
            "latitude": 38.237711,
            "longitude": -122.139456,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1499381015079_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40860821",
            "lid": "40860821",
            "addressStr": "<span>905 Lee Ln</span> <span>Concord</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/905-Lee-Ln-Concord-CA-94518/40860821_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/905-Lee-Ln-Concord-CA-94518/40860821_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40860821/0/0/0/0abac59a2630b033da1697a355a1d416/87/8b249809f31bd485c4dcac2396f6adb9/40860821.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40860821/0/0/0/0abac59a2630b033da1697a355a1d416/87/8b249809f31bd485c4dcac2396f6adb9/40860821.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40860821/0/0/0/0abac59a2630b033da1697a355a1d416/87/8b249809f31bd485c4dcac2396f6adb9/40860821.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1425000,
            "priceToShowStr": "$1,425,000",
            "squareFeet": "2957",
            "lotSize": "65340",
            "lotFeet": 65340,
            "lotAcres": 1.5,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "3",
            "listDate": "04/12/19",
            "expiresDate": "",
            "saleDate": "05/21/19",
            "daysOnMarket": "39",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "The estate sits on 1.5 acre flat lot, 1 year old remodel, just stunning! 3 buildings consist of main house, pool bathroom/ 4 car workshop, 2 car garage detached in addition to 4 car attached. Vaulted ceilings, crown moldings, Gleaming hardwood floors, wainscoting, recessed lighting, new heat/air, new waterheater, covered large patio with recessed lighting, fuit trees and so much more.. Bordering walnut creek, 5 min. to BART, shopping center, freeway and restaurants, great location",
            "county": "CONTRA COSTA",
            "geoArea": "5701",
            "distance": null,
            "yearBuilt": "1955",
            "latitude": 37.93879,
            "longitude": -122.0399,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40860821/0/0/0/0abac59a2630b033da1697a355a1d416/87/8b249809f31bd485c4dcac2396f6adb9/40860821.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40728920",
            "lid": "40728920",
            "addressStr": "<span>10 WHITEHALL DR</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/10-WHITEHALL-DR-Orinda-CA-94563/40728920_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/10-WHITEHALL-DR-Orinda-CA-94563/40728920_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1258000,
            "priceToShowStr": "$1,258,000",
            "squareFeet": "1848",
            "lotSize": "10168",
            "lotFeet": 10168,
            "lotAcres": 0.233,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "3",
            "listDate": "02/13/16",
            "expiresDate": "",
            "saleDate": "03/04/16",
            "daysOnMarket": "20",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.10whitehall.com",
            "description": "Lovely house in the \"Golden Triangle\", Walk to all 3 top rated schools, pride of ownership, new refinished hardwood floors throughout, double pane windows, many upgrades, secluded serene yard, one of the best locations. close to MV and MCC clubs, shopping and restaurants",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1959",
            "latitude": 37.8473108,
            "longitude": -122.1504555,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40728920/0/0/0/d3c2b1388508505cfd4cc5eef723bfc0/22/40c9016059d634ba856f3d72df800849/40728920.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40755913",
            "lid": "40755913",
            "addressStr": "<span>4 OVERHILL RD</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/4-OVERHILL-RD-Orinda-CA-94563/40755913_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/4-OVERHILL-RD-Orinda-CA-94563/40755913_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1175000,
            "priceToShowStr": "$1,175,000",
            "squareFeet": "2480",
            "lotSize": "17750",
            "lotFeet": 17750,
            "lotAcres": 0.407,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "09/05/16",
            "expiresDate": "",
            "saleDate": "10/14/16",
            "daysOnMarket": "39",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Dream, dream location. 2 min. walk to restaurants, Thetre and BART. Totally remodeled with custom kitchen, hardwood floors through out. Private yard with elegant entry. Every room is bright, light and overlooks garden. Extra storage, workshop, large garage, outdoot hot tub. Top rated schools.",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1957",
            "latitude": 37.8763199,
            "longitude": -122.178707,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40755913/0/0/0/7f26980ecbba065bfa32f54bb8f89959/22/e6987c19df7ff8a03eeaf41dc7388519/40755913.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40668472",
            "lid": "40668472",
            "addressStr": "<span>54 HEATHER LN</span> <span>Orinda</span> <span>CA 94563-3519</span>",
            "detailsURL": "https://agrealty1.com/IDX/54-HEATHER-LN-Orinda-CA-94563-3519/40668472_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/54-HEATHER-LN-Orinda-CA-94563-3519/40668472_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG",
            "middleThumbnailUrl": "https://drive.google.com/uc?export=view&id=19ecYvzZ_LDgq-tScO07TgnGs5oEd0GOm",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 1100000,
            "priceToShowStr": "$1,100,000",
            "squareFeet": "2105",
            "lotSize": "33600",
            "lotFeet": 33600,
            "lotAcres": 0.771,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "2",
            "listDate": "07/31/14",
            "expiresDate": "",
            "saleDate": "09/30/14",
            "daysOnMarket": "61",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Beautifully remodeled home with wonderful floor plan and majestic views of hills and Mt Diablo, Separate in-law 1 bed/1ba, large premium lot, quiet street and private. Just minutes to Meadow swim club, Glorietta Elementary, Downtown and Bart",
            "county": "CONTRA COSTA",
            "geoArea": "5300",
            "distance": null,
            "yearBuilt": "1967",
            "latitude": 37.8720655,
            "longitude": -122.1661566,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40668472/0/0/0/2e7e96bec92e8ae60eb63fe63dcdabea/22/6654bd1d0c4f2e75e4aaef70eed008fc/40668472.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641266",
            "lid": null,
            "addressStr": "<span>807 Tunbridge</span><span>Danville</span><span>CA</span>",
            "detailsURL": "https://agrealty1.com/IDX/807-Tunbridge-Danville/12732641266_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/807-Tunbridge-Danville/12732641266_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1046372573531.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/807_tunbridge_rd.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1046372573531.jpg",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 1069000,
            "priceToShowStr": "$1,069,000",
            "squareFeet": "0",
            "lotSize": null,
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": null,
            "bathTotalAsStr": "3",
            "listDate": "11/06/02",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "6058",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": null,
            "longitude": null,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1046372573531.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641401",
            "lid": null,
            "addressStr": "<span>18 Crestview Terrace</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641401_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641401_PM/0005601",
            "pictureURL": null,
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/18_crestview_ter.jpg",
            "smallThumbnailUrl": null,
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 984000,
            "priceToShowStr": "$984,000",
            "squareFeet": null,
            "lotSize": ".99",
            "lotFeet": null,
            "lotAcres": 0.99,
            "bedrooms": "4",
            "bathFull": "4.5",
            "bathPart": null,
            "bathAsStr": "4.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4.5",
            "listDate": "05/04/10",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "3322",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1982",
            "latitude": 37.8573168,
            "longitude": -122.1600161,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                null
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641362",
            "lid": null,
            "addressStr": "<span>18 Crestview Terrace</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641362_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641362_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1272997088589.jpg",
            "middleThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1272997088589.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1272997088589.jpg",
            "status": "Other",
            "listingClass": "Single Family Res",
            "priceToShow": 984000,
            "priceToShowStr": "$984,000",
            "squareFeet": null,
            "lotSize": ".99",
            "lotFeet": null,
            "lotAcres": 0.99,
            "bedrooms": "4",
            "bathFull": "4.5",
            "bathPart": null,
            "bathAsStr": "4.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4.5",
            "listDate": "05/04/10",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "3322",
            "age": null,
            "statusPresentation": null,
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1982",
            "latitude": 37.8573168,
            "longitude": -122.1600161,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1272997088589.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40857134",
            "lid": "40857134",
            "addressStr": "<span>1320 Canyon Side Ave</span> <span>San Ramon</span> <span>CA 94582</span>",
            "detailsURL": "https://agrealty1.com/IDX/1320-Canyon-Side-Ave-San-Ramon-CA-94582/40857134_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/1320-Canyon-Side-Ave-San-Ramon-CA-94582/40857134_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40857134/0/0/0/051bd669914fcc8b15393b093e008bc8/87/bd008a8ac3e9f16f8c3ef3b6ed75fc11/40857134.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40857134/0/0/0/051bd669914fcc8b15393b093e008bc8/87/bd008a8ac3e9f16f8c3ef3b6ed75fc11/40857134.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40857134/0/0/0/051bd669914fcc8b15393b093e008bc8/87/bd008a8ac3e9f16f8c3ef3b6ed75fc11/40857134.JPG",
            "status": "Sold",
            "listingClass": "Single Res",
            "priceToShow": 982500,
            "priceToShowStr": "$982,500",
            "squareFeet": "2125",
            "lotSize": "4620",
            "lotFeet": 4620,
            "lotAcres": 0.11,
            "bedrooms": "3",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": "0",
            "bathTotalAsStr": "3",
            "listDate": "03/15/19",
            "expiresDate": "",
            "saleDate": "04/25/19",
            "daysOnMarket": "41",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": "https://www.1320canyonsideavenue.com",
            "description": "Stunning two-story house in a gated community with gorgeous views of the golf course. Updated gourmet kitchen and bathrooms, gated front courtyard with fountain, luscious flowering shrubs and magnificent Japanese Maple. Open floor plan, opens to entertainers dream backyard with hot tub, fire pit, large pergola. Many amenities such as abundant storage, new Hickory floors, double fireplace. Walking distance to shopping, dinning, entertainment and easy access to freeway.",
            "county": "CONTRA COSTA",
            "geoArea": "4400",
            "distance": null,
            "yearBuilt": "1986",
            "latitude": 37.76505,
            "longitude": -121.94028,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40857134/0/0/0/051bd669914fcc8b15393b093e008bc8/87/bd008a8ac3e9f16f8c3ef3b6ed75fc11/40857134.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641368",
            "lid": null,
            "addressStr": "<span>18 Crestview Terrace</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641368_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641368_PM/0005601",
            "pictureURL": null,
            "middleThumbnailUrl": null,
            "smallThumbnailUrl": null,
            "status": "Other",
            "listingClass": "Single Family Res",
            "priceToShow": 975000,
            "priceToShowStr": "$975,000",
            "squareFeet": null,
            "lotSize": null,
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": null,
            "bathFull": null,
            "bathPart": null,
            "bathAsStr": null,
            "bathPartAsStr": null,
            "bathTotalAsStr": null,
            "listDate": "04/16/10",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "3340",
            "age": null,
            "statusPresentation": null,
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.8573168,
            "longitude": -122.1600161,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                null
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641367",
            "lid": null,
            "addressStr": "<span>18 Crestview Terrace</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641367_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/18-Crestview-Terrace-Orinda-CA-94563/12732641367_PM/0005601",
            "pictureURL": null,
            "middleThumbnailUrl": null,
            "smallThumbnailUrl": null,
            "status": "Other",
            "listingClass": "Single Family Res",
            "priceToShow": 975000,
            "priceToShowStr": "$975,000",
            "squareFeet": "3521",
            "lotSize": ".99",
            "lotFeet": null,
            "lotAcres": 0.99,
            "bedrooms": "4",
            "bathFull": "4.5",
            "bathPart": null,
            "bathAsStr": "4.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "4.5",
            "listDate": "04/16/10",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "3340",
            "age": null,
            "statusPresentation": null,
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1982",
            "latitude": 37.8573168,
            "longitude": -122.1600161,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                null
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641351",
            "lid": null,
            "addressStr": "<span>8237 Skyline Circle</span> <span>Oakland</span> <span>CA 94605</span>",
            "detailsURL": "https://agrealty1.com/IDX/8237-Skyline-Circle-Oakland-CA-94605/12732641351_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/8237-Skyline-Circle-Oakland-CA-94605/12732641351_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1164152511996.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/8237_skyline_circle.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1164152511996.jpg",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 929000,
            "priceToShowStr": "$929,000",
            "squareFeet": "0",
            "lotSize": ".50",
            "lotFeet": null,
            "lotAcres": 0.5,
            "bedrooms": "4",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "11/21/06",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "4582",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "2004",
            "latitude": 37.773195,
            "longitude": -122.133612,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1164152511996.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641360",
            "lid": null,
            "addressStr": "<span>64 Muth Drive</span> <span>Orinda </span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/64-Muth-Drive-Orinda--CA-94563/12732641360_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/64-Muth-Drive-Orinda--CA-94563/12732641360_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1245201956261.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/64_muth_dr.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1245201956261.jpg",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 927500,
            "priceToShowStr": "$927,500",
            "squareFeet": "2000",
            "lotSize": "33000",
            "lotFeet": 33000,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "3",
            "bathPart": null,
            "bathAsStr": "3",
            "bathPartAsStr": null,
            "bathTotalAsStr": "3",
            "listDate": "06/16/09",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "3644",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1979",
            "latitude": 37.887701,
            "longitude": -122.17152,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1245201956261.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641249",
            "lid": null,
            "addressStr": "<span>41 Meadow View Rd</span> <span>Orinda</span> <span>CA 94563</span>",
            "detailsURL": "https://agrealty1.com/IDX/41-Meadow-View-Rd-Orinda-CA-94563/12732641249_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/41-Meadow-View-Rd-Orinda-CA-94563/12732641249_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1367261682985.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/41_meadow_view_rd.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1367261682985.jpg",
            "status": "Sold",
            "listingClass": "Residential",
            "priceToShow": 925000,
            "priceToShowStr": "$925,000",
            "squareFeet": "1631",
            "lotSize": ".43",
            "lotFeet": null,
            "lotAcres": 0.43,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "04/15/13",
            "expiresDate": "",
            "saleDate": "04/23/13",
            "daysOnMarket": "8",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1951",
            "latitude": 37.877684,
            "longitude": -122.157409,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1367261682985.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641349",
            "lid": null,
            "addressStr": "<span>631 37th Ave</span><span>San Francisco</span><span>CA</span>",
            "detailsURL": "https://agrealty1.com/IDX/631-37th-Ave-San-Francisco/12732641349_PM/0005606",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/631_37th_ave.jpg",
            "detailsClientViewURL": "https://agrealty1.com/IDX/631-37th-Ave-San-Francisco/12732641349_PM/0005601",
            "pictureURL": null,
            "smallThumbnailUrl": null,
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 905000,
            "priceToShowStr": "$905,000",
            "squareFeet": "0",
            "lotSize": "1800",
            "lotFeet": 1800,
            "lotAcres": null,
            "bedrooms": "5",
            "bathFull": "3.25",
            "bathPart": null,
            "bathAsStr": "3.25",
            "bathPartAsStr": null,
            "bathTotalAsStr": "3.25",
            "listDate": "04/19/04",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "5528",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1931",
            "latitude": null,
            "longitude": null,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                null
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641372",
            "lid": null,
            "addressStr": "<span>3195 Stanley Blvd</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/3195-Stanley-Blvd-Lafayette-CA-94549/12732641372_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/3195-Stanley-Blvd-Lafayette-CA-94549/12732641372_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1353185793535.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/3195_stanley_blvd.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1353185793535.jpg",
            "status": "Sold",
            "listingClass": "Acreage",
            "priceToShow": 899000,
            "priceToShowStr": "$899,000",
            "squareFeet": null,
            "lotSize": null,
            "lotFeet": null,
            "lotAcres": null,
            "bedrooms": null,
            "bathFull": null,
            "bathPart": null,
            "bathAsStr": null,
            "bathPartAsStr": null,
            "bathTotalAsStr": null,
            "listDate": "08/31/12",
            "expiresDate": "",
            "saleDate": "11/16/12",
            "daysOnMarket": "77",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.904038,
            "longitude": -122.093555,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1353185793535.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        // {
        //     "ls": "MAXEBRDI",
        //     "lsLabelShort": "PARAGON",
        //     "listingKey": "40804673",
        //     "lid": "40804673",
        //     "addressStr": "<span>718 E 24th</span> <span>Oakland</span> <span>CA 94606-2043</span>",
        //     "detailsURL": "https://agrealty1.com/IDX/718-E-24th-Oakland-CA-94606-2043/40804673_MAXEBRDI/0005606",
        //     "detailsClientViewURL": "https://agrealty1.com/IDX/718-E-24th-Oakland-CA-94606-2043/40804673_MAXEBRDI/0005601",
        //     "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
        //     "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
        //     "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG",
        //     "status": "Sold",
        //     "listingClass": "Residential Income",
        //     "priceToShow": 880000,
        //     "priceToShowStr": "$880,000",
        //     "squareFeet": "3120",
        //     "lotSize": "5000",
        //     "lotFeet": 5000,
        //     "lotAcres": 0.12,
        //     "bedrooms": null,
        //     "bathFull": null,
        //     "bathPart": null,
        //     "bathAsStr": null,
        //     "bathPartAsStr": null,
        //     "bathTotalAsStr": "0",
        //     "listDate": "11/29/17",
        //     "expiresDate": "",
        //     "saleDate": "01/02/18",
        //     "daysOnMarket": "34",
        //     "age": null,
        //     "statusPresentation": "Sold",
        //     "virtualTourLink": null,
        //     "description": "Rare opportunity to own this 4 unit property located in the highly desirable Ivy Hill neighborhood. Spacious units with separate dining room. Front units have city light views. Coin-operated laundry site in basement for additional income. While being close to Lake Merritt you can also take advantage of the nearby dining and retail scene taking place on Grand Avenue and Lakeshore Avenue, just minutes away. Bart, AC transit lines, I-880 and I-580 are all with-in minutes of access. Huge upside in rents.",
        //     "county": "ALAMEDA",
        //     "geoArea": "2606",
        //     "distance": null,
        //     "yearBuilt": "1941",
        //     "latitude": 37.800603,
        //     "longitude": -122.242259,
        //     "parkingTotal": null,
        //     "inFavorites": false,
        //     "featured": false,
        //     "officeListing": false,
        //     "newListing": false,
        //     "mostRecent": false,
        //     "openHouse": null,
        //     "codedParams": "0005606",
        //     "priceUp": false,
        //     "priceDown": false,
        //     "uniListingClass": "MUL",
        //     "pictures": [
        //         "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40804673/0/0/0/d59a18bc5463a4ac9344ee6c707d1201/52/e7147ca29773cde2e32f953d1f5ef097/40804673.JPG"
        //     ],
        //     "disclosuresUrl": null,
        //     "onListRequired": "A.G. Realty",
        //     "onThumbnailIconRequired": null,
        //     "onThumbnailRequired": null,
        //     "clientViewCount": null,
        //     "isClientDelete": null,
        //     "isRealtorDelete": null,
        //     "isClientWantToSee": null,
        //     "isClientWantMoreInfo": null,
        //     "clientLastSeeDate": null,
        //     "clientLastAppointmentDate": null,
        //     "clientLastMoreInfoDate": null,
        //     "favoriteSetUpDate": null
        // },
        {
            "ls": "MAXEBRDI",
            "lsLabelShort": "PARAGON",
            "listingKey": "40767724",
            "lid": "40767724",
            "addressStr": "<span>W Clover Rd</span> <span>Tracy</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/W-Clover-Rd-Tracy-CA-94518/40767724_MAXEBRDI/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/W-Clover-Rd-Tracy-CA-94518/40767724_MAXEBRDI/0005601",
            "pictureURL": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "middleThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "smallThumbnailUrl": "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG",
            "status": "Sold",
            "listingClass": "Commercial Lots and Land",
            "priceToShow": 875000,
            "priceToShowStr": "$875,000",
            "squareFeet": null,
            "lotSize": "83208",
            "lotFeet": 83208,
            "lotAcres": 1.91,
            "bedrooms": null,
            "bathFull": null,
            "bathPart": null,
            "bathAsStr": null,
            "bathPartAsStr": null,
            "bathTotalAsStr": "0",
            "listDate": "01/17/17",
            "expiresDate": "",
            "saleDate": "05/02/18",
            "daysOnMarket": "470",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": "Was approved for strip shopping center. Commercial PUD zoning. Suitable for various uses as long as one follows code for Highway commercial zoning. City will not allow any business with noise pollution like auto repair. Behind In and Out and fast food restaurants, busy intersection, visibility from Fwy 205, all flat rectangular lot.",
            "county": "SAN JOAQUIN",
            "geoArea": "9915",
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.7614038,
            "longitude": -121.4372004,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "COM",
            "pictures": [
                "https://mls-images-proxy.acceleragent.net/cdnparap30.paragonrels.com/ParagonImages/Property/P3/MAXEBRDI/40767724/0/0/0/681627aa80660698a6f5c88812425216/52/23d4416b8441d0a5bc58b41cb03285c9/40767724.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": "A.G. Realty",
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641267",
            "lid": null,
            "addressStr": "<span>426 Springfield Place</span> <span>Moraga</span> <span>CA 94518</span>",
            "detailsURL": "https://agrealty1.com/IDX/426-Springfield-Place-Moraga/12732641267_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/426-Springfield-Place-Moraga/12732641267_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1057773148546.JPG",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/498_springfield_pl.jpg",
            "smallThumbnailUrl": "https://isvr.acceleragent.com/usr/1001754436/properties/1057773148546.JPG",
            "status": "Sold",
            "listingClass": "Single Family Res",
            "priceToShow": 849000,
            "priceToShowStr": "$849,000",
            "squareFeet": "0",
            "lotSize": ".75",
            "lotFeet": null,
            "lotAcres": 0.75,
            "bedrooms": "4",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "07/09/03",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "5813",
            "age": null,
            "statusPresentation": "Sold",
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1965",
            "latitude": null,
            "longitude": null,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "RES",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1057773148546.JPG"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "PM",
            "lsLabelShort": "PM",
            "listingKey": "12732641428",
            "lid": null,
            "addressStr": "<span>637 Laird Lane</span> <span>Lafayette</span> <span>CA 94549</span>",
            "detailsURL": "https://agrealty1.com/IDX/637-Laird-Lane-Lafayette-CA-94549/12732641428_PM/0005606",
            "detailsClientViewURL": "https://agrealty1.com/IDX/637-Laird-Lane-Lafayette-CA-94549/12732641428_PM/0005601",
            "pictureURL": "https://isvr.acceleragent.com/usr/1001754436/properties/1434651138970_0.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/637_laird_lane.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/637_laird_lane.jpg",
            "status": "Other",
            "listingClass": "Acreage",
            "priceToShow": 837000,
            "priceToShowStr": "$837,000",
            "squareFeet": null,
            "lotSize": "9600",
            "lotFeet": 9600,
            "lotAcres": null,
            "bedrooms": "3",
            "bathFull": "2.5",
            "bathPart": null,
            "bathAsStr": "2.5",
            "bathPartAsStr": "1",
            "bathTotalAsStr": "2.5",
            "listDate": "03/18/15",
            "expiresDate": "",
            "saleDate": "04/23/15",
            "daysOnMarket": "36",
            "age": null,
            "statusPresentation": null,
            "virtualTourLink": null,
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": "1967",
            "latitude": 37.9576629,
            "longitude": -122.106102,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1434651138970_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
        },
        {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>198 William Way</span> <span>Pittsburg</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/198_William_Way_thumbnail.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 460000,
            "priceToShowStr": "$460,000",
            "squareFeet": 1492,
            "lotSize": null,
            "lotFeet": 5200,
            "lotAcres": null,
            "bedrooms": "4",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      },
      {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>465 Sandstone Dr</span> <span>Vallejo</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/465_Sandstone_thumbnail.jpg",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 275000,
            "priceToShowStr": "$275,000",
            "squareFeet": 1056,
            "lotSize": null,
            "lotFeet": 3029,
            "lotAcres": null,
            "bedrooms": "2",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
                "https://isvr.acceleragent.com/usr/1001754436/properties/1538183425153_0.jpg"
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      },
      {
            "ls": "",
            "lsLabelShort": "",
            "listingKey": "",
            "lid": null,
            "addressStr": "<span>331 Swan Way</span> <span>Vallejo</span> <span>CA 00000</span>",
            "detailsURL": "",
            "detailsClientViewURL": "",
            "pictureURL": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "middleThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "smallThumbnailUrl": "https://s3.us-south.cloud-object-storage.appdomain.cloud/homes/331_swan_way_thumbnail.png",
            "status": "Sold",
            "listingClass": "",
            "priceToShow": 374000,
            "priceToShowStr": "$374,000",
            "squareFeet": 1374,
            "lotSize": null,
            "lotFeet": 6098,
            "lotAcres": null,
            "bedrooms": "3",
            "bathFull": "2",
            "bathPart": null,
            "bathAsStr": "2",
            "bathPartAsStr": null,
            "bathTotalAsStr": "2",
            "listDate": "",
            "expiresDate": "",
            "saleDate": "",
            "daysOnMarket": "",
            "age": null,
            "statusPresentation": "New",
            "virtualTourLink": "",
            "description": null,
            "county": null,
            "geoArea": null,
            "distance": null,
            "yearBuilt": null,
            "latitude": 37.9091254,
            "longitude": -122.1679971,
            "parkingTotal": null,
            "inFavorites": false,
            "featured": false,
            "officeListing": false,
            "newListing": false,
            "mostRecent": false,
            "openHouse": null,
            "codedParams": "0005606",
            "priceUp": false,
            "priceDown": false,
            "uniListingClass": "LOT",
            "pictures": [
            ],
            "disclosuresUrl": null,
            "onListRequired": null,
            "onThumbnailIconRequired": null,
            "onThumbnailRequired": null,
            "clientViewCount": null,
            "isClientDelete": null,
            "isRealtorDelete": null,
            "isClientWantToSee": null,
            "isClientWantMoreInfo": null,
            "clientLastSeeDate": null,
            "clientLastAppointmentDate": null,
            "clientLastMoreInfoDate": null,
            "favoriteSetUpDate": null
      }
    ],
    "title": "Your Real Estate Results",
    "favoritesCount": 0
}