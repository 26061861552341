import React, { Component } from 'react'
import { Menu, Grid, Card, Segment, Container, Header, Image, Button } from 'semantic-ui-react'
import './App.css';

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = { width: window.innerWidth }
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.setState({
      width: window.innerWidth
    }));
  }

  render(){
    const servicesData = [
      {
        imgUrl: "https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage1.jpg",
        type: "Staging",
        caption: "Detail oriented, perfectly placed",
        description: "Carefully chosen stagers whose styles complement your homes best features."
      },
      {
        imgUrl: "https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage2.jpg",
        type: "Creativity",
        caption: "Custom Photography",
        description: "A key factor to our success. Our creativity is mainly derived from our knowledge and experience and allows us to market each property in their own special way."
      },
      {
        imgUrl: "https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage3.jpg",
        type: "Marketing Campaigns",
        caption: "Large outreach & Targeted",
        description: "We use Cutting- Edge Technology for all our properties. We combine a variety of marketing strategies from traditional print promotions such as newspaper advertisements, luxury magazines, and direct mailings to electronic flyers, personal websites and social media."
      },
      {
        imgUrl: "https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage4.jpg",
        type: "Market Analysis",
        caption: "Data Driven Decisions",
        description: "Our strong comprehension of market trends provides clients with the greatest education and advantage. We gather and analyze data to fully understand the subject property’s market area. The quality of the market analysis can properly determine the accurate and competitive pricing to maximize the greatest exposure and highest price."
      },
      {
        imgUrl: "https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage5.jpg",
        type: "Network",
        caption: "Providing Ideal Resources",
        description: "We strongly believe that great professionals align themselves with other great professionals. Through-out the years we compiled a comprehensive list of our large network of business connections. Our data bases allow us to take care of all the responsibilities of buying or selling a property and make the process easy and enjoyable for our clients."
      }
    ]
    if(this.state.width < 600)
      return (
        <div>
      <h1 style={{textAlign:'left',
        marginLeft: '100px',
        marginTop: '50px',
        marginBottom: '50px',
      }}></h1>

      <div className="services">
        <Grid>
          {servicesData.map((service) => (
            <Grid.Row columns={1}>
              <Grid.Column>
                <img src={service.imgUrl} />
              </Grid.Column>
              <Grid.Column>
                <div className="serviceDescription" style={{
                  left: '0px',
                  marginLeft: '5%',
                  width: '95%',
                  height: '450px'
                }}>
                  <div style={{position:'relative', top:'50px'}}>
                  <hr style={{
                      marginLeft: '0px',
                      width: '100px',
                      marginBottom: '-50px',
                      height: '8px',
                      backgroundColor: '#5c4817',
                      border: 0,
                      textAlign: 'left',
                  }}/>
                  <h2 className="sectionHead" style={{
                    marginTop: '100px',
                    fontWeight:'100',
                    marginBottom: '-25px',
                    textAlign: 'left'
                  }}>{service.type}</h2>
                  <h2 className="sectionHead" style={{
                    marginBottom:'50px',
                    fontWeight: '700',
                    textAlign: 'left'
                  }}>{service.caption}</h2>
                  <p style={{width:'90%', textAlign: 'left'}}>
                    {service.description}
                  </p>
                </div>
              </div>
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </div>
      </div>
        );
    else
      return (
      <div>
      <h1 style={{textAlign:'left',
        marginLeft: '100px',
        marginTop: '50px',
        marginBottom: '50px',
      }}></h1>

      <div className="services">
        <Grid>
          <Grid.Row columns={2} style={{
              padding: `0px`
            }}>
            <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription">
                <div style={{position:'relative', top:'50px'}}>
                <hr style={{
                    marginTop: '100px',
                    marginBottom: '-50px',
                    marginLeft: '0px',
                    width: '100px',
                    height: '8px',
                    backgroundColor: '#5c4817',
                    border: 0,
                    textAlign: 'left'
                }}/>
                <h2 className="sectionHead" style={{
                  marginTop: '100px',
                  fontWeight:'100',
                  marginBottom: '-25px',
                  textAlign: 'left'
                }}>Staging</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left'
                }}>Detail oriented, perfectly placed</h2>
                <p style={{width:'90%', textAlign: 'left'}}>
                  Carefully chosen stagers whose styles complement your homes best features.
                </p>
              </div>
              </div>
            </Grid.Column>
            <Grid.Column width={11} style={{
              padding: `0px`,
            }}>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage1.jpg" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{
              padding: `0px`
            }}>
            <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription">
                <div style={{position:'relative', top:'50px'}}>
                <hr style={{
                    marginTop: '100px',
                    marginBottom: '-50px',
                    marginLeft: '0px',
                    width: '100px',
                    height: '8px',
                    backgroundColor: '#5c4817',
                    border: 0,
                    textAlign: 'left'
                }}/>
                <h2 className="sectionHead" style={{
                  marginTop: '100px',
                  fontWeight:'100',
                  marginBottom: '-25px',
                  textAlign: 'left'
                }}>Creativity</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left'
                }}>Custom Photography</h2>
                <p style={{width:'90%', textAlign: 'left'}}>
                  A key factor to our success. Our creativity is mainly derived from our knowledge and experience and allows us to market each property in their own special way.
                </p>
              </div>
              </div>
            </Grid.Column>
            <Grid.Column width={11} style={{
              padding: `0px`,
            }}>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage2.jpg" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{
              padding: `0px`
            }}>
            <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription">
                <div style={{position:'relative', top:'50px'}}>
                <hr style={{
                    marginTop: '100px',
                    marginBottom: '-50px',
                    marginLeft: '0px',
                    width: '100px',
                    height: '8px',
                    backgroundColor: '#5c4817',
                    border: 0,
                    textAlign: 'left'
                }}/>
                <h2 className="sectionHead" style={{
                  marginTop: '100px',
                  fontWeight:'100',
                  marginBottom: '-25px',
                  textAlign: 'left'
                }}>Marketing Campaigns</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left'
                }}>Large outreach & Targeted</h2>
                <p style={{width:'90%', textAlign: 'left'}}>
                   We use Cutting- Edge Technology for all our properties. We combine a variety of marketing strategies from traditional print promotions such as newspaper advertisements, luxury magazines, and direct mailings to electronic flyers, personal websites and social media.
                </p>
              </div>
              </div>
            </Grid.Column>
            <Grid.Column width={11} style={{
              padding: `0px`,
            }}>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage3.jpg" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{
              padding: `0px`
            }}>
            <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription">
                <div style={{position:'relative', top:'50px'}}>
                <hr style={{
                    marginTop: '100px',
                    marginBottom: '-50px',
                    marginLeft: '0px',
                    width: '100px',
                    height: '8px',
                    backgroundColor: '#5c4817',
                    border: 0,
                    textAlign: 'left'
                }}/>
                <h2 className="sectionHead" style={{
                  marginTop: '100px',
                  fontWeight:'100',
                  marginBottom: '-25px',
                  textAlign: 'left'
                }}>Market Analysis</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left'
                }}>Data Driven Decisions</h2>
                <p style={{width:'90%', textAlign: 'left'}}>
                  Our strong comprehension of market trends provides clients with the greatest education and advantage. We gather and analyze data to fully understand the subject property’s market area. The quality of the market analysis can properly determine the accurate and competitive pricing to maximize the greatest exposure and highest price.
                </p>
              </div>
              </div>
            </Grid.Column>
            <Grid.Column width={11} style={{
              padding: `0px`,
            }}>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage4.jpg" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2} style={{
              padding: `0px`
            }}>
            <Grid.Column width={5} style={{
              padding: `0px`
            }}>
              <div className="serviceDescription">
                <div style={{position:'relative', top:'50px'}}>
                <hr style={{
                    marginTop: '100px',
                    marginBottom: '-50px',
                    marginLeft: '0px',
                    width: '100px',
                    height: '8px',
                    backgroundColor: '#5c4817',
                    border: 0,
                    textAlign: 'left'
                }}/>
                <h2 className="sectionHead" style={{
                  marginTop: '100px',
                  fontWeight:'100',
                  marginBottom: '-25px',
                  textAlign: 'left'
                }}>Network</h2>
                <h2 className="sectionHead" style={{
                  marginBottom:'50px',
                  fontWeight: '700',
                  textAlign: 'left'
                }}>Providing Ideal Resources</h2>
                <p style={{width:'90%', textAlign: 'left'}}>
                  We strongly believe that great professionals align themselves with other great professionals. Through-out the years we compiled a comprehensive list of our large network of business connections. Our data bases allow us to take care of all the responsibilities of buying or selling a property and make the process easy and enjoyable for our clients.
                </p>
              </div>
              </div>
            </Grid.Column>
            <Grid.Column width={11} style={{
              padding: `0px`,
            }}>
              <img src="https://s3.us-south.cloud-object-storage.appdomain.cloud/website-images/stage5.jpg" />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
      </div>
    );
  }
}


export default Services